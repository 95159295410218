import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../widgets/login/login.component';
import { RegisterUserComponent } from '../widgets/register-user/register-user.component';
import { UserStoreService } from 'src/app/core/services/user-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.scss']
})
export class LoginMobileComponent implements OnInit {

  isLogin: boolean = false;

  constructor(public dialog: MatDialog, public storeService: UserStoreService, public router: Router) { }

  ngOnInit() {
    this.verificarToken();
  }

  verificarToken() {
    this.isLogin = this.storeService.validToken();
  }

  iniciarSesion() {
    this.dialog.open(LoginComponent, {
      width: '350px',
    });
  }

  registro() {
    this.dialog.open(RegisterUserComponent, {
      width: '350px',
    });
  }

  logout(): void {
    sessionStorage.removeItem('infoCliente');
    sessionStorage.removeItem('metodoPago');
    sessionStorage.removeItem('token');
    window.location.reload();
  }

  restaurarPassword() {
    this.router.navigate(['restaurar-password']);
}

}
