import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-shop",
  templateUrl: "./shop.component.html",
  styleUrls: ["./shop.component.scss"],
})
export class ShopComponent implements OnInit {
  public url: any;
  hiddeUrls = [
    "/productos/lanzamientos",
    "/productos/verificacion-pedido",
    "/atencion-clientes/cambio-productos",
    '/resumen-verificacion-pedido',
    "/atencion-clientes/abono-apartado"
  ];
  isHide = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;

        for (const url of this.hiddeUrls) {
          if (this.url.indexOf(url) !== -1) {
            this.isHide = true;
            return;
          } else {
            this.isHide = false;
          }
        }
      }
    });
  }

  ngOnInit() {
    $.getScript("assets/js/script.js");
  }
}
