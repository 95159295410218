import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orden-en-proceso',
  templateUrl: './orden-en-proceso.component.html',
  styleUrls: ['./orden-en-proceso.component.scss']
})
export class OrdenEnProcesoComponent implements OnInit {

  orden: string = 'C23B6F635E';

  constructor() { }

  ngOnInit() {
  }

}
