import Swal from 'sweetalert2';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Notificaciones {

  constructor() {}

  carga(texto: string): Promise<any> {
    return Swal.fire({
      title: texto + ', por favor espere',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  cerrar() {
    return Swal.close();
  }

  exito(texto: string, ocultarConExito?: boolean): Promise<any> {
    return Swal.fire({
      icon: 'success',
      title: 'Correcto',
      text: ocultarConExito ? texto : '¡' + texto + ' con éxito!',
    });
  }

  error(texto: string): Promise<any> {
    return Swal.fire({
      title: 'Error',
      text: texto,
      icon: 'error'
    });
  }

  advertencia(texto: string): Promise<any> {
    return Swal.fire({
      title: 'Advertencia',
      text: texto,
      icon: 'warning'
    });
  }

  informacion(texto: string): Promise<any> {
    return Swal.fire({
      title: 'Información',
      text: texto,
      icon: 'info'
    });
  }

  confirmacion(texto: string): Promise<any>  {
    return Swal.fire({
      title: 'Confirmación',
      html: '¿ Estás seguro que ' + texto + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D9743',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    });
  }
  
  choseBetweenTwo() : Promise<any> {
    return Swal.fire({
      title: 'Confirmación',
      html: 'Elige el método de pago',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D9743',
      cancelButtonColor: '#0D9743',
      confirmButtonText: 'Pago en efectivo o transferencia',
      cancelButtonText: 'Pago con tarjeta de crédito o débito',
    });
  }
}
