import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-slider-range",
  templateUrl: "./slider-range.component.html",
  styleUrls: ["./slider-range.component.scss"],
})
export class SliderRangeComponent implements AfterViewInit {
  @Input() max: number = 1000;
  @Input() min: number = 0;
  @Input() value: number = 0;
  @Input() full: boolean = false;
  @Input() title: string = "Precio";
  @Input() theme: string = "";

  @Output() valueChange = new EventEmitter<number>();

  @ViewChild("rangeSlider", { static: false })
  rangeSlider: ElementRef<HTMLInputElement>;

  @ViewChild("rangeLabel", { static: false })
  rangeLabel: ElementRef<HTMLSpanElement>;

  maxValueInput: number = 200;
  // sliderValue = 0;

  ngAfterViewInit(): void {
    // this.showSlideValue();
    // const porcentaje = ((this.value - this.min) / (this.max - this.min)) * 100;

    // Ahora, establece el valor del control deslizante en la mitad
    this.rangeSlider.nativeElement.value = this.value.toString();
  }

  showSlideValue() {
    const range = this.rangeSlider.nativeElement;
    const rangeV = this.rangeLabel.nativeElement;

    const newValue = Number(
      ((Number(range.value) - Number(range.min)) * 100) /
        (Number(range.max) - Number(range.min))
    );


    rangeV.style.left = `calc(${newValue}%)`;
    rangeV.innerHTML = String(`$${Number(range.value).toLocaleString("en")}`);
    this.valueChange.emit(Number(range.value));
  }

  get getPercent() {
    return this.value;
  }
}
