import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-home-textos-slider",
  templateUrl: "./home-textos-slider.component.html",
  styleUrls: ["./home-textos-slider.component.scss"],
})
export class HomeTextosSliderComponent implements AfterViewInit {
  items = [
    { text: "COMPRA A MSI", color: "#BE1622", type: "text" },
    { text: "●", color: "#BE1622", type: "icon" },
    { text: "Envío gratis a partir de $4,000", color: "#000", type: "text" },
    { text: "●", color: "#BE1622", type: "icon" },
    { text: "Paga tu par a plazos", color: "#000", type: "text" },
    { text: "●", color: "#BE1622", type: "icon" },
    {
      text: "Paga por transferencia y recibe 5% off",
      color: "#BE1622",
      type: "text",
    },
    { text: "●", color: "#BE1622", type: "icon" },
    // { text: "COMPRA A MSI", color: "#000", type: "text" },
    // { text: "●", color: "#BE1622", type: "icon" },
  ];

  @ViewChild("slider", { static: true }) slider: ElementRef<HTMLDivElement>;

  @Input() isHideMobile: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngAfterViewInit(): void {
    const copy = document.querySelector(".textos-slide").cloneNode(true);
    document.querySelector(".slider").appendChild(copy);
    this.resizeScreen();
  }

  resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 750px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches && this.isHideMobile) {
          this.hideSlider();
        } else {
          this.showSlider();
        }
      });
  }

  hideSlider(): void {
    this.slider.nativeElement.style.display = "none";
  }

  showSlider(): void {
    this.slider.nativeElement.style.display = "block";
  }
}
