import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewChildren,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
} from "@angular/core";
import { LandingFixService } from "../../core/services/landing-fix.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { RegisterUserComponent } from "./widgets/register-user/register-user.component";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "./widgets/login/login.component";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { CarritoService } from "../../core/services/nuevaLogica/checkout/carrito.service";
import { UserstoreService } from "../../core/services/nuevaLogica/clientes/userstore.service";
import { MarcasService } from "src/app/core/services/nuevaLogica/productos/catalogos/marcas.service";
import { ToastrService } from "ngx-toastr";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { easing } from "jquery";
import { HeaderMenu } from "src/app/core/interfaces/header/headerInterface";
import { CustomerKartService } from "src/app/core/services/nuevaLogica/checkout/customer-kart.service";
import { KartResults } from "src/app/core/interfaces/checkout/carrito";
import { ImageUploadService } from 'src/app/core/services/image-upload.service';
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
    trigger("submenu", [
      transition("void => *", [
        style({ height: "0", opacity: 0 }),
        animate("150ms ease-in-out", style({ height: "*", opacity: 1 })),
      ]),
      transition("* => void", [
        style({ height: "*" }),
        animate("150ms ease-in-out", style({ height: "0" })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  elementosCarrito: number;
  public show = false;
  tokenIsValid: boolean;
  user: any;
  subCategorias: any;
  public showMenu = false;
  showLogin = false;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  uploadedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  isPopupOpen = false;
  @Input() nombreActualizado: string;
  menus: Array<any> = [
    {
      name: "Sneakers",
      image: "assets/images/jor.png",
      url: {
        producto: "sneakers",
      },
      subcategorias: [
        {
          sub: "Hombre",
          url: {
            producto: "sneakers",
            categoria: "hombre",
            pagina: 1,
          },
          img: "assets/images/jordan5.jpg",
        },
        {
          sub: "Mujer",
          url: {
            producto: "sneakers",
            categoria: "mujer",
            pagina: 1,
          },
          img: "assets/images/dior.jpg",
        },
        {
          sub: "Niños",
          url: {
            producto: "sneakers",
            categoria: "nino",
            pagina: 1,
          },
          img: "assets/images/nino.jpg",
        },
        /*{
          'sub': 'Bebé',
          'url': {
            producto: 'sneakers',
            categoria: 'bebe'
          },
          'img': 'assets/images/bebe.jpg',
        },*/
        {
          sub: "Todos",
          url: {
            producto: "sneakers",
            pagina: 1,
          },
          img: "assets/images/nino.jpg",
        },
        /*        {
                  'sub': 'Outlet',
                  'url': 'collection/6',
                  'img': 'assets/images/disc.jpg',
                  'id': '6'
                },
        {
          'sub': 'Artículos de limpieza',
          url: {
            producto: 'crep',
          },
          'img': 'assets/images/creep.jpg',
        },*/
      ],
      drop: true,
    },
    {
      name: "Retail",
      url: "/sneakers-retail?pagina=1",
      drop: false,
    },
    {
      name: "Ropa",
      image: "assets/images/ropa.jpg",
      url: "/productos/ropa",
      subcategorias: [
        {
          sub: "Chamarras y Hoodies",
          url: {
            producto: "chamarra",
            pagina: 1,
          },
          img: "assets/images/chamarra.jpg",
        },
        {
          sub: "Pants",
          url: {
            producto: "pants",
            pagina: 1,
          },
          img: "assets/images/pants.jpg",
        },
        {
          sub: "Shorts",
          url: {
            producto: "short",
            pagina: 1,
          },
          img: "assets/images/short.jpg",
        },
        {
          sub: "Playeras",
          url: {
            producto: "playera",
            pagina: 1,
          },
          img: "assets/images/playera.jpg",
        },
      ],
      drop: true,
    },
    {
      name: "Marcas",
      subcategorias: [],
      drop: true,
    },
    /*{
      'name': 'Distribuidores',
      'subcategorias': [
        {
          'sub': 'Con inversión',
          'url': '/distribuidores/con-inversion',
          'sections': []
        },
        {
          'sub': 'Independientes',
          'url': '/distribuidores/independientes',
          'sections': []
        },
      ]
    },*/
  ];
  suscribeCarrito: Subscription;
  menuCategorias = false;
  yeezy = "Yeezy";
  userName = "";

  showSubrandsMenu = false;
  subbrandList = [];
  subbrandName = null;
  brandData = null;

  newMenu: HeaderMenu[] = [
    /* {
      name: "Hombre",
      subcategorias: [
        { name: "Marcas", list: [] },
        {
          name: "Ropa",
          list: [
            {
              name: "Chamarras & Hoodies",
              url: "/productos",
              params: {
                producto: "chamarra",
                pagina: 1,
              },
            },
            {
              name: "Pants",
              url: "/productos",
              params: {
                producto: "pants",
                pagina: 1,
              },
            },
            {
              name: "Shorts",
              url: "/productos",
              params: {
                producto: "short",
                pagina: 1,
              },
            },
            {
              name: "Playeras",
              url: "/productos",
              params: {
                producto: "playera",
                pagina: 1,
              },
            },
          ],
        },
        { name: "Lanzamientos", list: [] },
      ],
      drop: true,
    }, */
    {
      name: "Marcas",
    subcategorias: [],
    drop: true
  },
    {
      name: "Retail",
      subcategorias: [],
      drop: false,
      url: "sneakers-retail",
      urlParams: { pagina: 1 },
    },
    {
      name: "Ropa",
      subcategorias: [
        {
          name: "",
          list: [
            {
              name: "Chamarras & Hoodies",
              url: "/productos",
              params: {
                producto: "chamarra",
                pagina: 1,
              },
            },
            {
              name: "Pants",
              url: "/productos",
              params: {
                producto: "pants",
                pagina: 1,
              },
            },
            {
              name: "Shorts",
              url: "/productos",
              params: {
                producto: "short",
                pagina: 1,
              },
            },
            {
              name: "Playeras",
              url: "/productos",
              params: {
                producto: "playera",
                pagina: 1,
              },
            },
          ],
        },
      ],
      drop: true,
    },
    {
      name: "Prime",
      subcategorias: [],
      drop: false,
      url: "/prime",
    },
    {
      name: "Lanzamientos",
      subcategorias: [],
      drop: false,
      url: "/productos/lanzamientos",
    },
    {
      name: "Descuentos",
      subcategorias: [
        {
          name: "",
          list: [
            {
              name: "Descuentos",
              url: "/productos/descuentos",
              params: {
                pagina: 1,
              },
            },
            {
              name: "Descuentos Limitados",
              url: "/productos/descuentos-limitados",
            },
          ],
        },
      ],
      drop: true,
      /* url: "/productos/descuentos",
      urlParams: { pagina: 1 }, */
    },
    {
      name: "Subastas",
      subcategorias: [],
      drop: false,
      url: "/productos/subastas",
    },
    {
      name: "Partners",
      subcategorias: [],
      drop: false,
      url: "/sales-partners",
    },
    {
      name: "Blog",
      subcategorias: [],
      drop: false,
      url: "/blog",
    },
  ];

  showSubmenu: boolean;

  isTransparentBackground = true;
  isActiveSearch = false;
  hideSections = false;
  searchValue = "";
  cartItems: any;

  @Input() set isTransparent(value: boolean) {
    this.isTransparentBackground = value;
    this.setStyle();
  }
  @ViewChild("menu", { static: true }) menu: ElementRef<HTMLDivElement>;
  @ViewChild("sections", { static: true }) sections: ElementRef<HTMLDivElement>;
  @ViewChild("searchInput", { static: true })
  searchInput: ElementRef<HTMLDivElement>;
  @ViewChild("actions", { static: true }) actions: ElementRef<HTMLDivElement>;
  @ViewChild("logo", { static: true }) logo: ElementRef<HTMLDivElement>;
  @ViewChild("searchContent", { static: true })
  searchContent: ElementRef<HTMLDivElement>;
  @ViewChild("search", { static: true }) search: ElementRef<HTMLDivElement>;

  @HostListener("window:resize", ["$event"])
  onWindowResize($event: any) {
    if (!this.isActiveSearch) return;
    console.log($event);
    this.setStyleSearch();
  }

  isMobile: boolean;
  currentMenu: any;
  showMenuMobile: boolean;
  private unlistener: () => void;

  isDark = false;

  constructor(
    private fix: LandingFixService,
    private loginService: UserstoreService,
    private carritoService: CarritoService,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private marcasService: MarcasService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private customerKart: CustomerKartService,
    private imageUploadService: ImageUploadService
  ) {
    this.resizeScreen();
    this.getUrl();

    this.tokenIsValid = this.loginService.validToken();
    this.loadedCustomerKart(this.tokenIsValid);

    
    // this.tokenIsValid = loginService.validToken();
  }

  ngOnInit() {
    $.getScript("assets/js/menu.js");
    if (this.tokenIsValid) {
      this.getUser();
    }

    // this.getMarks();
    this.getBrandsWithSubBrands();
  }

  getUrl() {
    // subscribe route get url name
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((res: any) => {
        this.isDark = false;
        if (res.url) {
          const url = res.url;
          // search include word on url
          // ["descuentos", "subastas"].indexOf
          // console.log(url);
          this.isDark =
            url.search("descuentos") > -1 ||
            url.search("subastas") > -1 ||
            url.search("prime") > -1;
        }
      });
  }

  ngOnDestroy(): void {
    this.suscribeCarrito.unsubscribe();
  }

  ngAfterViewInit() {
    const $src = fromEvent(window, "scroll").pipe(debounceTime(400));
    $src.subscribe(() => {
      if (this.showLogin) {
        this.showLogin = false;
      }
    });

    this.setStyle();

    // fromEvent(document, "click").subscribe((e) => {
    //   if (!this.showSubrandsMenu) return;

    //   const target = e.target as HTMLElement;

    //   const el = document.querySelector("#subbrand-menu");

    //   if (el.contains(target)) return;

    //   this.subbrandList = [];
    //   this.subbrandName = null;
    //   this.showSubrandsMenu = false;
    //   this.brandData = null;
    // });
  }

  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  openSearch() {
    this.show = true;
    // this.leaveSubmenuAction();
  }

  closeSearch() {
    this.show = false;
  }

  openDialog(): void {
    this.dialog.open(RegisterUserComponent, {
      width: "350px",
    });
  }

  openLogin(): void {
    this.dialog.open(LoginComponent, {
      // maxWidth: "400px",
      minWidth: "355px",
      // width: "100%",
    });
  }

  logout(): void {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("infoCliente");
    sessionStorage.removeItem("metodoPago");
    window.location.reload();
  }

  getUser() {
    this.loginService.getUser().subscribe((data) => {
      this.user = data;
      this.userName = data.nombre;
    });
  }

  toggleSubbrandsMenu(event: MouseEvent, subbrandData: any, brand: any) {
    event.stopPropagation();

    const show = subbrandData.sub !== this.subbrandName;

    if (show) {
      this.subbrandList = subbrandData.subrands;
      this.subbrandName = subbrandData.sub;
      this.showSubrandsMenu = true;
      this.brandData = brand;
    } else {
      this.subbrandList = [];
      this.subbrandName = null;
      this.showSubrandsMenu = false;
      this.brandData = null;
    }
  }

  getBrandsWithSubBrands() {
    this.marcasService.getMarcasSubmarcas().subscribe({
      next: (data) => {
        const brandsWithSubbrands = [];
  
        data.forEach((item: any) => {
          const brand = {
            name: item.descripcion,
            url: "/marcas/" + item.descripcionURL,
            params: { pagina: 1 },
            subcategories: []
          };
  
          // Agregar las submarcas a la categoría de la marca
          item.submarca.forEach((submarca: any) => {
            const subMarcaUrl = `/marcas/${item.descripcionURL}/submarcas/${submarca.descripcionUrl}`;
            /* console.log('URL de la submarca:', subMarcaUrl); */ // Agrega este console.log para depurar
            brand.subcategories.push({
                name: submarca.descripcion,
                url: subMarcaUrl,
                params: { pagina: 1 }
            });
        });
        
  
          // Agregar la marca con sus submarcas al arreglo brandsWithSubbrands
          brandsWithSubbrands.push(brand);
        });
  
        // Actualizar la estructura de menú con marcas y submarcas
        const menuBrandsItemIndex = this.newMenu
          .map((item) => item.name)
          .indexOf("Marcas");
  
        if (menuBrandsItemIndex !== -1) {
          // Asignar marcas y submarcas al menú
          this.newMenu[menuBrandsItemIndex].subcategorias = brandsWithSubbrands;
  
          // Asignar marcas y submarcas al arreglo list en los elementos correspondientes del newMenu
          this.newMenu[menuBrandsItemIndex].subcategorias.forEach((marca: any) => {
            marca.list = []; // Limpiar el arreglo de list para evitar duplicados
            marca.subcategories.forEach((submarca: any) => {
              marca.list.push({
                name: submarca.name,
                url: submarca.url // Aquí se asigna la URL de la submarca
              });
            });
          });
        }
      },
    });
  }
  
  
    

  /* getMarks() {
    this.marcasService.get().subscribe({
      next: (data) => {
        const subs = [];

        const marksToMenu = data.map((x) => ({
          name: x.descripcion,
          url: "/marcas/" + x.descripcionUrl,
          params: {
            pagina: 1,
          },
        }));

        console.log(marksToMenu);

        this.newMenu[0].subcategorias[0]["list"] = [...marksToMenu];
        this.newMenu[3].subcategorias[0]["list"] = [...marksToMenu];
 */
        // for (let i = 0; i < data.length; i++) {
        //   subs.push({
        //     sub: data[i].descripcion,
        //     img: "assets/images/nino.jpg",
        //     url: data[i].descripcionUrl,
        //   });
        // }

        // for (let j = 0; j < this.menus.length; j++) {
        //   if (this.menus[j].name == "Marcas") {
        //     console.log(subs);

        //     this.menus[j].subcategorias = subs;
        //   }
        // }
      /* },
    });
  } */

  showMobileMenu() {
    this.showMenu = !this.showMenu;
  }

  showMobileMenuNav() {
    setTimeout(() => {
      this.showMenu = !this.showMenu;
    }, 300);
  }

  abrirWhatsapp(numero: string) {
    window.open(
      "https://api.whatsapp.com/send?phone=+" +
        numero +
        "&text=Hola%20requiero%20informes%20de%20un%20producto%20del%20ecommerce",
      "_blank"
    );
  }

  async shareURL(
    event: MouseEvent,
    url: string,
    type: "marca" | "submarca" = "marca"
  ) {
    event.stopPropagation();

    const urlToShare = `${window.location.origin}/marcas/${url}`;
    const messageToShare = `Checa esta ${type} en Sneaker Store:`;
    const textToShare = `${messageToShare}\n${urlToShare}`;

    try {
      if (!window.navigator) return;

      if ((window.navigator as any).share) {
        await (window.navigator as any).share({ text: textToShare });
        this.toastrService.success("Enlace copiado");
        console.log("Compartido:", textToShare);
      } else if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(textToShare);
        this.toastrService.success("Enlace copiado");
        console.log("Copiado al clipboard:", textToShare);
      }
    } catch (error) {
      this.toastrService.error("Ha ocurrido un error al compartir el link");
      console.log("Error: Ha ocurrido un error al compartir el link");
    }
  }

  // --new logic

  showSubmenuAction(menu) {
    console.log({ menu });
    if (menu.subcategorias.length > 0) {
      this.showSubmenu = true;
      this.menu.nativeElement.style.backgroundColor = "#fff";
      this.currentMenu = menu;
    } else {
      this.showSubmenu = false;
    }
  }

  leaveSubmenuAction(): void {
    this.showSubmenu = false;
    if (this.isTransparentBackground) {
      this.menu.nativeElement.style.backgroundColor = "transparent";
    }
  }

  setStyle() {
    const style = this.menu.nativeElement.style;

    if (this.isTransparentBackground && !this.showSubmenu) {
      style.position = "transparent";
    } else {
      style.position = "#fff";
    }

    console.log(this.isTransparentBackground);

    if (this.isTransparentBackground) {
      style.position = "absolute";
      style.top = "53px"; //le agregamos la altura del topbar component
      style.left = "0";
      style.width = "100%";
      style.zIndex = "5";
    } else {
      style.position = "sticky";
      style.backgroundColor = "white";
      style.top = "0";
    }
  }

  resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 1162px)"])
      .subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
      });

    this.listenClick();
  }

  openUser(): void {
    if (!this.tokenIsValid) return this.openLogin();
    this.router.navigate(["/usuario"]);
  }

  onSearch(): void {
    this.isActiveSearch = !this.isActiveSearch;
    !this.isActiveSearch ? this.hideSearch() : this.setStyleSearch();
    this.showSubmenu = false;
  }

  setStyleSearch(): void {
    const widthActions = this.actions.nativeElement.offsetWidth;
    const widthLogo = this.logo.nativeElement.offsetWidth;
    const windowWidth = window.innerWidth;

    this.searchContent.nativeElement.style.display = "block";

    const calc = windowWidth - widthLogo - widthActions;
    const px = calc > 800 ? 800 : calc;
    this.searchInput.nativeElement.style.width = px + "px";

    this.hideSections = true;
  }

  hideSearch(): void {
    this.searchContent.nativeElement.style.display = "none";
    this.hideSections = false;
    this.searchInput.nativeElement.style.width = "200px";
  }

  listenClick(): void {
    this.unlistener = this.renderer.listen("window", "click", (e: any) => {
      const nativeSearch = this.search.nativeElement;
      const nativeSearchContent = this.searchContent.nativeElement;
      // const nativeSearchInput = this.searchInput.nativeElement;

      if (nativeSearch.contains(e.target) && !this.isActiveSearch) {
        return this.onSearch();
      }

      if (nativeSearch.contains(e.target) && this.isActiveSearch) return;

      [nativeSearch, nativeSearchContent].forEach((el) => {
        if (el.contains(e.target) && this.isActiveSearch) {
          return;
        }
        if (!el.contains(e.target) && this.isActiveSearch) {
          this.hideSearch();
          this.isActiveSearch = false;
        }
      });
    });
  }

  toLink(menu) {
    console.log(menu);
    if (menu.url) {
      this.router.navigate([menu.url], {
        queryParams: menu.urlParams,
      });
    }
  }

  goToHome() {
    this.closeSearch();
    this.router.navigate(["home"], {queryParams: {gender: localStorage.getItem("gender")}})
  }

  loadedCustomerKart(isValid: boolean) {
    if (isValid) {
      this.customerKart.getCustomerKart()
      .subscribe((data:KartResults) => {
        if (data.productos.length > 0) {
          // this.cartItems = data.productos as any;
          this.carritoService.prepararCustumeKart(data.productos);
        }

        const session = sessionStorage.getItem("carrito");
        if (session) {
          this.elementosCarrito = JSON.parse(window.atob(session)).length;
        }
        this.suscribeCarrito = this.carritoService.elementosCarrito.subscribe(
          (elementosCarrito) => (this.elementosCarrito = elementosCarrito.length)
        );
      });
    } else {
      const session = sessionStorage.getItem("carrito");
        if (session) {
          this.elementosCarrito = JSON.parse(window.atob(session)).length;
        }
        this.suscribeCarrito = this.carritoService.elementosCarrito.subscribe(
          (elementosCarrito) => (this.elementosCarrito = elementosCarrito.length)
        );
    }
  }

  cerrarBuscador() {
    this.hideSearch();
  }

  openPopup(event: MouseEvent): void {
    event.stopPropagation(); // Evita la propagación del evento
    event.preventDefault();  // Evita el comportamiento predeterminado si hay alguno
  
    this.isPopupOpen = true; // Abre el popup
    console.log('Popup abierto con un solo clic'); // Depuración
  }
  
  

  // Método para cerrar el popup
  closePopup() {
    this.isPopupOpen = false;
  }

  // Método para seleccionar el archivo desde el input
  triggerFileInput(event: Event) {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  // Método que se llama cuando se selecciona un archivo a través del input
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.processFile(file);
      this.closePopup();
    }
  }

  // Método para manejar el evento dragover
  onDragOver(event: DragEvent) {
    event.preventDefault(); // Evita la acción predeterminada de abrir el archivo en el navegador
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy'; // Cambia el cursor al símbolo de "copiar"
  }

  // Método para manejar el evento drop
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.processFile(file);
      this.closePopup();
    }
  }

  // Método para procesar el archivo (subir imagen o realizar otras acciones)
  processFile(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUploadService.updateImageUrl(reader.result as string); // Actualiza la URL en el servicio
        this.router.navigate(['/image-search']); // Navega al componente de búsqueda de imágenes
      };
      reader.readAsDataURL(file);
    }
  }
}
