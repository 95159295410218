import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  private imageUrlSource = new BehaviorSubject<string | ArrayBuffer | null>(null);
  currentImageUrl = this.imageUrlSource.asObservable();

  constructor() {}

  updateImageUrl(url: string | ArrayBuffer | null) {
    this.imageUrlSource.next(url);
  }
}