import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CotizaFormComponent } from "./shared/generales/cotiza-form/cotiza-form.component";
import { ContactFormComponent } from "./shared/generales/contact-form/contact-form.component";
import { LlamaFormComponent } from "./shared/generales/llama-form/llama-form.component";
import { FavoritesService } from "./core/services/favorites.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private favoritesService: FavoritesService
  ) {}

  ngOnInit(): void {
    this.getFavorites();
  }

  llamaForm(): void {
    this.dialog.open(LlamaFormComponent, {
      width: "350px",
    });
  }

  cotizaForm(): void {
    this.dialog.open(CotizaFormComponent, {
      width: "350px",
    });
  }

  contactForm(): void {
    this.dialog.open(ContactFormComponent, {
      width: "350px",
    });
  }

  abrirWhatsapp(numero: string) {
    window.open(
      "https://api.whatsapp.com/send?phone=+" +
        numero +
        "&text=Hola%20requiero%20informes%20de%20un%20producto%20del%20ecommerce",
      "_blank"
    );
  }

  getFavorites(): void {
    this.favoritesService.getFavorites().subscribe();
  }
}
