import { Component, OnInit } from '@angular/core';
import { CompareService } from 'src/app/core/services/compare-service.service';
import { ChatGptService } from "src/app/core/services/nuevaLogica/chat-gpt.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-compare-product',
  templateUrl: './compare-product.component.html',
  styleUrls: ['./compare-product.component.scss']
})
export class CompareProductComponent implements OnInit {
  productos: any[] = [];
  descripcion: string;
  $subs = new Subject<void>();
  description1: string;
  description2: string;
  descripDim1: string;
  descripDim2: string;
  talla: string[]; // Añadido para almacenar las tallas

  constructor(private compareService: CompareService,
              private chatGptService: ChatGptService) {}

  ngOnInit(): void {
    this.productos = this.compareService.getProductos();
    this.inicializarTallas(); // Llama a la función para inicializar las tallas
    this.getModaSimilares();
    this.getHistorialPar();
  }

  // Función para inicializar las tallas
  private inicializarTallas(): void {
    this.talla = [
      "22.5",
      "23",
      "23.5",
      "23.5",
      "24",
      "24",
      "24.5",
      "25",
      "25.5",
      "26",
      "26.5",
      "27",
      "27.5",
      "28",
      "28.5",
      "29",
      "29.5",
      "30",
      "30.5",
      "31",
      "31.5",
      "32"
    ];
  }

  calculateDiscountedPrice(producto: any): number {
    if (producto.idTipoDescuento != null) {
      if (producto.idTipoDescuento === 1) {
        return producto.precioVenta - (producto.precioVenta * producto.descuento) / 100;
      }
      return producto.precioVenta - producto.descuento;
    }
    return producto.precioVenta;
  }

  private getModaSimilares(): void {
    if (this.productos[0]) {
      this.chatGptService.getModaSimilares(Number(this.productos[0].id))
        .pipe(takeUntil(this.$subs))
        .subscribe((res) => {
          this.description1 = res["descripcion"];
        });
    }

    if (this.productos[1]) {
      this.chatGptService.getModaSimilares(Number(this.productos[1].id))
        .pipe(takeUntil(this.$subs))
        .subscribe((res) => {
          this.description2 = res["descripcion"];
        });
    }
  }

  private getHistorialPar(): void {
    if (this.productos[0]) {
      this.chatGptService
        .getHistorialPar(Number(this.productos[0].id))
        .pipe(takeUntil(this.$subs))
        .subscribe((res) => {
          this.descripDim1 = res["descripcion"];
        });
    }

    if (this.productos[1]) {
      this.chatGptService
        .getHistorialPar(Number(this.productos[1].id))
        .pipe(takeUntil(this.$subs))
        .subscribe((res) => {
          this.descripDim2 = res["descripcion"];
        });
    }
  }

  goToLinkProd0() {
    window.location.href = "/productos/detalle/" + this.productos[0].descripcionURL;
  }

  goToLinkProd1() {
    window.location.href = "/productos/detalle/" + this.productos[1].descripcionURL;
  }

  goBack(): void {
    window.history.back();
  }
}
