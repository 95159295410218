import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ProductToCompare {
  id: string;
  nombre: string;
  imagen: string;
  idTallaProducto?: number;
  talla?: string;
  idDescuento?: number;
  idTipoDescuento?: number;
  descuento?: number;
  precioVenta?: number;
  descripcionURL?: string; 
}

@Injectable({
  providedIn: 'root'
})
export class CompareService {
  private productoSubject = new BehaviorSubject<ProductToCompare[]>([]);
  productos$ = this.productoSubject.asObservable();

  constructor() {
    this.loadFromSessionStorage();
  }

  addProducto(producto: ProductToCompare): void {
    const productos = this.productoSubject.getValue();
    if (productos.length < 2) {
      productos.push(producto);
    } else {
      // Elimina el primer producto y agrega el nuevo
      productos.shift(); // Elimina el primer producto
      productos.push(producto); // Agrega el nuevo producto
    }
    this.productoSubject.next(productos);
    this.saveToSessionStorage();
    console.log('Producto agregado o sustituido:', producto);
  }
  

  removeProducto(productoId: string): void {
    let productos = this.productoSubject.getValue();
    productos = productos.filter((producto) => producto.id !== productoId);
    this.productoSubject.next(productos);
    this.saveToSessionStorage();

    console.log('Producto eliminado:', productoId);
    console.log('Productos restantes:', productos);
  }

  private saveToSessionStorage(): void {
    const productos = this.productoSubject.getValue();
    sessionStorage.setItem('productosComparar', JSON.stringify(productos));
  }

  private loadFromSessionStorage(): void {
    const data = sessionStorage.getItem('productosComparar');
    if (data) {
      const productos: ProductToCompare[] = JSON.parse(data);
      this.productoSubject.next(productos);
    }
  }

  getProductos(): ProductToCompare[] {
    return this.productoSubject.getValue();
  }
}
