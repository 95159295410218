import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { Producto } from "../../../interfaces/productos/Producto";

@Injectable({
  providedIn: "root",
})
export class ProductosService {
  url: string;
  urlVerificacion: string;
  basicUrl: string;
  urlOferta: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlProductos + "ecommerce";
    this.urlVerificacion = environment.apiUrlVerificacion;
    this.basicUrl = environment.apiUrlOrdenes + "catalogos/metodo-pago";
    this.urlOferta = environment.apiUrlEcommerce;
    
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.basicUrl}`);
  }

  get(queryParams): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(queryParams),
    };
    return this.http.get(this.url, requestOptions);
  }

  getSubbrandProducts(queryParams): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(queryParams),
    };

    return this.http.get(`${this.url}-submarca`, requestOptions);
  }

  getRetail(queryParams): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(queryParams),
    };
    return this.http.get(this.url + "-retail", requestOptions);
  }

  getPreventa(): Observable<any> {
    return this.http.get(`${this.url}/preventa`);
  }

  getByDescripcion(descripcion: string): Observable<any> {
    return this.http.get(this.url + `/${descripcion}`);
  }

  getTallas(idEmpresaProducto: string): Observable<any> {
    return this.http.get(`${this.url}/talla-producto/${idEmpresaProducto}`);
  }

  getMasNuevos() {
    return this.http.get(`${this.url}/mas-nuevos`);
  }

  getMasVendidos() {
    return this.http.get(`${this.url}/mas-vendidos`);
  }

  getMasActivas() {
    return this.http.get(`${this.url}/marcas-activas`);
  }

  getLanzamientos(): Observable<Producto[]> {
    return this.http.get<Producto[]>(
      `${environment.apiUrlBack}/lanzamientos/get-lanzamientos`
    );
  }

  getNewLanzamientos(): Observable<any> {
    return this.http.get(`${this.url}/lanzamientos`);
  }

  getEntregaInmediata() {
    return this.http.get(`${this.url}/entrega-inmediata`);
  }

  getDescuentosNew(): Observable<any> {
    return this.http.get(
      `${this.urlVerificacion}get-stock-productos-good-state`
    );
  }

  getColores() {
    return this.http.get(`${environment.apiUrlProductos}get-colores`);
  }

  getLimitDiscountProduct() {
    return this.http.get(`${this.urlVerificacion}get-limit-discount-product`);
  }

  getOfertaLimit(id: number) {
    return this.http.get(`${this.urlOferta}ofertas/ptpActive/${id}`);
  }

  getSugerencias(): Observable<any> {
    return this.http.get(`${environment.apiUrlProductos}sugerencias`);
  }

  agregarOfertaSubasta(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrlBack}/ordenes/add-subasta-product-offer`,
      data
    );
  }
}
