import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Producto } from 'src/app/core/interfaces/productos/Producto';
import { SeleccionarTallaComponent } from './seleccionar-talla/seleccionar-talla.component';

@Component({
  selector: 'app-tarjeta-productos-seleccion',
  templateUrl: './tarjeta-productos-seleccion.component.html',
  styleUrls: ['./tarjeta-productos-seleccion.component.scss'],
})
export class TarjetaProductosSeleccionComponent implements OnInit {
  @Input() producto: Producto;
  @Input() seleccionActiva: any[];
  @Output() productoSeleccionadoEmmit: EventEmitter<any> = new EventEmitter();
  productoSeleccionado = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.productoSeleccionado =
      this.seleccionActiva.filter(
        (producto) => producto.descripcionURL === this.producto.descripcionURL
      ).length > 0;
  }

  seleccionarTalla() {
    this.dialog
      .open(SeleccionarTallaComponent, { data: this.producto.descripcionURL })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.productoSeleccionado = true;
          this.productoSeleccionadoEmmit.emit(data);
        }
      });
  }
}
