import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-x2',
  templateUrl: './banner-x2.component.html',
  styleUrls: ['./banner-x2.component.scss']
})
export class BannerX2Component implements OnInit {
  @Input() imgLarge: string;
  @Input() imgSmall: string;

  constructor() { }

  ngOnInit() {
  }

}
