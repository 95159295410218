import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

interface Chatgpt {
  descripcion: string;
}

@Injectable({
  providedIn: "root",
})
export class ChatGptService {
  url = `${environment.apiUrlBack}/productos`;

  constructor(private http: HttpClient) {}

  getHistorialPar(body): Observable<Chatgpt> {
    return this.http.post<Chatgpt>(`${this.url}/product-description-gpt`, {
      id: body,
    });
  }

  getCuidados(body): Observable<Chatgpt> {
    return this.http.post<Chatgpt>(
      `${this.url}/product-description-gpt-cuidados`,
      {
        id: body,
      }
    );
  }

  getModaSimilares(body): Observable<Chatgpt> {
    return this.http.post<Chatgpt>(`${this.url}/product-description-gpt-moda`, {
      id: body,
    });
  }
}
