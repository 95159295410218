import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CarritoService } from "src/app/core/services/nuevaLogica/checkout/carrito.service";

@Component({
  selector: "app-header-short",
  templateUrl: "./header-short.component.html",
  styleUrls: ["./header-short.component.scss"],
})
export class HeaderShortComponent implements OnInit {
  isMobile = false;
  elementosCarrito: number;
  $subs = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private carritoService: CarritoService
  ) {
    const session = sessionStorage.getItem("carrito");
    if (session) {
      this.elementosCarrito = JSON.parse(window.atob(session)).length;
    }
    this.$subs = this.carritoService.elementosCarrito.subscribe(
      (elementosCarrito) => (this.elementosCarrito = elementosCarrito.length)
    );
  }

  ngOnInit() {
    this.resizeScreen();
  }

  resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 1162px)"])
      .subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
      });
  }
}
