import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Orden } from "../../../interfaces/checkout/orden";
import { Observable } from "rxjs";
import { Rastreo } from "src/app/core/interfaces/productos/rastreo.interface";
import { MITBody } from "src/app/core/interfaces/checkout/payment";

@Injectable({
  providedIn: "root",
})
export class OrdenesService {
  url: string;
  urlSimple: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlOrdenes + "ecommerce";
    this.urlSimple = environment.apiUrlOrdenes;
  }

  post(orden: any): Observable<any> {
    return this.http.post(this.url, orden);
  }

  postCambio(orden): Observable<any> {
    return this.http.post(`${this.url}/cambio`, orden);
  }

  postApartado(body): Observable<any> {
    return this.http.post(`${this.urlSimple}apartado-ecommerce`, body);
  }

  postAbonoApartado(body) {
    return this.http.post(`${this.urlSimple}abono-apartado`, body);
  }

  getAbonosOrden(numeroFolio: any) {
    return this.http.get(`${this.urlSimple}get-abonos-orden/${numeroFolio}`);
  }

  getOrderByFolio(numeroFolio: any) {
    return this.http.get(`${this.urlSimple}get-by-folio/${numeroFolio}`);
  }

  getOrderById(id: any) {
    return this.http.get(`${this.urlSimple}get-by-id/${id}`);
  }

  validacionDatosMail(infoCliente): Observable<any> {
    return this.http.post<any>(
      this.urlSimple + "validacion-datos",
      infoCliente
    );
  }

  enviarCorreo(datos: { orden: string; linkPago?: string; tipoPago: number }) {
    return this.http.post<any>(this.url + "/enviar-correo", datos);
  }

  adjuntarComprobante(datos) {
    return this.http.post<any>(
      environment.apiUrlFinanzas + 'recibo-orden', datos
    );
  }

  misOrdenes(body): Observable<any> {
    return this.http.post(`${this.url}/mis-ordenes`, body);
  }

  misApartados(): Observable<any> {
    const email = sessionStorage.getItem("userEmail");
    return this.http.post(`${this.url}/mis-apartados`, {email})
  }

  getNumeroFolio(folioOrden: string): Observable<Rastreo[]> {
    return this.http.get<Rastreo[]>(
      `${this.urlSimple}numero-folio/${folioOrden}`
    );
  }

  addChangeHistory(body): Observable<any> {
    return this.http.post(
      `${environment.apiUrlVerificacion}add-change-history`,
      body
    );
  }

  GetActiveSubasta(): Observable<any> {
    return this.http.get(`${this.urlSimple}get-active-subasta`);
  }

  AddSubastaProductOffer(body: any): Observable<any> {
    return this.http.post(`${this.urlSimple}add-subasta-product-offer`, body);
  }

  GetLastRewardCupon(body: any): Observable<any> {
    return this.http.post(`${this.urlSimple}get-last-reward-cupon`, body);
  }

  createLinkMIT(body: MITBody) {
    return this.http.post(
      `${environment.paymentUrl}/api/mit/create-link`,
      body
    );
  }
  verificarComprobante(folio): Observable<any> {
    return this.http.get(`${environment.apiUrlFinanzas}verificar-comprobante/${folio}`);
  }
}
