import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AngoliaSearchRequest } from "src/app/core/interfaces/atencion-clientes/angolia/AngoliaSearchRequest";
import { AngoliaService } from "src/app/core/services/nuevaLogica/angolia/angolia.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ProductosService } from "src/app/core/services/nuevaLogica/productos/productos.service";

@Component({
  selector: "app-angolia-search",
  templateUrl: "./angolia-search.component.html",
  styleUrls: ["./angolia-search.component.scss"],
})
export class AngoliaSearchComponent implements OnInit {
  allItems: any[] = [];
  allItemsRange: any[] = [];
  precioMaximo: number;
  precioMinimo: number;
  inicioRango: number;
  finRango: number;
  isOrdenar = true;

  inputSearch = "";

  marcasSeleccionadas = [];
  toppings = new FormGroup({});
  activas: any[] = [];
  facetFilters: string[][] = [];
  isChecked = false;
  isMobile = false;

  @Output() parentFun: EventEmitter<any> = new EventEmitter();
  @Input() set inputSearchValue(value: string) {
    this.inputSearch = value;
    // console.log({ value });
    if (!value || value === "") this.allItems = [];
    else this.buscarAlgolia(this.inputSearch, 256, "");
  }

  @Input() set isMobileInput(value: boolean) {
    this.isMobile = value;
  }

  suggestions = [
    { name: "adidas trainers", icon: "restart", isLabel: false },
    { name: "adidas gazelle", icon: "restart", isLabel: false },
    { name: "adidas trail shoes", icon: "restart", isLabel: false },
    { name: "adidas t-shirt", icon: "restart", isLabel: false },
    { name: "adidas", icon: "label-article", isLabel: true },
  ];

  constructor(
    private angoliaService: AngoliaService,
    private productosService: ProductosService
  ) {}

  ngOnInit() {
    // this.buscarAlgolia("", 256, "");
    this.getSugerencias();
    this.getMarcasActivas();
  }

  valueChangedFirst(valueInput, target, max, event) {
    const value = Math.min(valueInput, target.parentNode.childNodes[1].value);

    const bola = document.getElementById("primerBola");
    const primerGlobito = document.getElementById("primerGlobito");
    const primerGlobitovalue1 = document.getElementById("value1");
    bola.style.left = value + "px";
    primerGlobito.style.left = value + "px";
    this.inicioRango = Math.ceil((value * Math.ceil(this.precioMaximo)) / 200);
    primerGlobitovalue1.innerHTML =
      "$ " + this.inicioRango.toLocaleString("en");
  }

  valueChangedSecond(valueInput, target, max, event) {
    const value = Math.min(
      valueInput,
      target.parentNode.childNodes[2].value - -1
    );

    const bola = document.getElementById("segundaBola");
    const primerGlobito = document.getElementById("segundoGlobito");
    const primerGlobitovalue1 = document.getElementById("value");

    bola.style.right = 200 - value + "px";
    primerGlobito.style.right = 200 - value - 17 + "px";
    this.finRango = Math.ceil((value * Math.ceil(this.precioMaximo)) / 200);
    primerGlobitovalue1.innerHTML = "$ " + this.finRango.toLocaleString("en");
  }

  aplicarRango() {
    if (!this.finRango) {
      this.finRango = 250000;
    }
    if (!this.inicioRango) {
      this.inicioRango = 2000;
    }

    let inicioRangoValue = this.inicioRango;
    let finRangoValue = this.finRango;

    if (inicioRangoValue > finRangoValue) {
      let temp = inicioRangoValue;
      inicioRangoValue = finRangoValue;
      finRangoValue = temp;
    }

    const filters =
      "precioVenta: " +
      inicioRangoValue.toString() +
      " TO " +
      finRangoValue.toString();
    this.buscarAlgolia(this.inputSearch, 256, filters);
  }

  filtrar() {
    this.facetFilters = [];

    if (this.marcasSeleccionadas.length > 0) {
      const newArray: string[] = [];

      for (let i = 0; i < this.marcasSeleccionadas.length; i++) {
        newArray.push("marca:" + this.marcasSeleccionadas[i]);
      }

      this.facetFilters.push(newArray);
    }

    this.buscarAlgolia(this.inputSearch, 256, "");
    this.isChecked = false;
  }

  ordenar() {
    let arraySize, i, k, aux;
    arraySize = this.allItems.length;

    if (this.isOrdenar) {
      for (k = 1; k < arraySize; k++) {
        for (i = 0; i < arraySize - k; i++) {
          if (this.allItems[i].precioVenta > this.allItems[i + 1].precioVenta) {
            aux = this.allItems[i];
            this.allItems[i] = this.allItems[i + 1];
            this.allItems[i + 1] = aux;
          }
        }
      }
      this.isOrdenar = false;
    } else {
      this.isOrdenar = true;

      for (k = 1; k < arraySize; k++) {
        for (i = 0; i < arraySize - k; i++) {
          if (this.allItems[i].precioVenta < this.allItems[i + 1].precioVenta) {
            aux = this.allItems[i];
            this.allItems[i] = this.allItems[i + 1];
            this.allItems[i + 1] = aux;
          }
        }
      }
    }
  }

  buscarAlgolia(query, hitsPerPage, filters) {
    const data: AngoliaSearchRequest = {
      query: query,
      hitsPerPage: hitsPerPage,
      filters: filters,
      facetFilters: this.facetFilters,
      page: 0,
    };

    this.angoliaService.GetAngoliaResults(data).subscribe((response) => {
      this.allItems = response.hits;
      this.preciosParaRangos();
    });
  }

  preciosParaRangos() {
    if (this.allItems.length > 0) {
      let arraySize, d, f, aux;
      arraySize = this.allItems.length;

      let max = this.allItems[0].precioVenta;
      let min = this.allItems[0].precioVenta;

      for (d = 0; d < arraySize; d++) {
        if (this.allItems[d].precioVenta < min) {
          min = this.allItems[d].precioVenta;
        }

        if (this.allItems[d].precioVenta > max) {
          max = this.allItems[d].precioVenta;
        }
      }

      this.precioMaximo = Math.ceil(max);
      this.precioMinimo = Math.ceil(min);
    }
  }

  onChangeInputSearch() {
    this.buscarAlgolia(this.inputSearch, 256, "");
  }

  clearFilter() {
    this.marcasSeleccionadas = [];
    this.facetFilters = [];
    this.toppings.reset();
    this.buscarAlgolia(this.inputSearch, 256, "");
    this.isChecked = false;
  }

  clickCheck(checkValue: boolean, idMarca: any, descripcion: any, index: any) {
    if (checkValue) {
      this.marcasSeleccionadas.push(descripcion);
    } else {
      this.marcasSeleccionadas = this.marcasSeleccionadas.filter(
        (item) => item !== descripcion
      );
    }
  }
  quitarPill(marca: any) {
    this.marcasSeleccionadas = this.marcasSeleccionadas.filter(
      (item) => item !== marca
    );
    this.toppings.get(marca).reset();
  }

  private getMarcasActivas() {
    this.productosService.getMasActivas().subscribe({
      next: (data: any) => {
        this.activas = data;
        this.activas.forEach((val) => {
          this.toppings.addControl(val.descripcion, new FormControl(val.id));
        });
        this.toppings.reset();
      },
    });
  }

  getSugerencias() {
    this.productosService.getSugerencias()
    .subscribe(data => {
      this.suggestions = data.filter(i => i.estado == 1);
    });
  }

  closeFilters() {
    this.isChecked = false;
  }

  childCloseSearch() {
    this.parentFun.emit();
  }

  goToSuggestion(link) {
    window.location.href = "/productos/detalle/" + link;
  }
}
