import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerKart } from 'src/app/core/interfaces/checkout/carrito';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerKartService {

  private url: string;
  private urlVerificacion: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlProductos;
  }

  addCustomerKart(body: CustomerKart) {
    return this.http.post(this.url + 'add-customer-kart', body);
  }

  getCustomerKart() {
    return this.http.get(this.url + 'get-customer-kart');
  }

  deleteKart(body) {
    return this.http.post(this.url + 'delete-kart', body);
  }

  deleteAllKart() {
    return this.http.post(this.url + 'delete-all-kart', {"test": 1});
  }

  deleteCarritosAbandonados() {
    return this.http.post('https://backend.sneakerstore.com.mx/nueva-base/verificacion/eliminar-carritos-abandonados', {"test": 1})
  }

  updateCantidadKart(body) {
    return this.http.post(this.url + 'update-cantidad-kart', body);
  }
}
