import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTextSize]',
})
export class TextSizeDirective {
  constructor(elem: ElementRef<HTMLSpanElement>, renderer: Renderer2) {
    setTimeout(() => {
      const text = elem.nativeElement.innerHTML;
      if (text && text.length > 22) {
        renderer.setStyle(elem.nativeElement, 'font-size', '15px');
      }
    }, 50);
  }
}
