import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatCheckbox, MatDialogRef, MatRadioButton} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {NewsletterService} from '../../../../core/services/nuevaLogica/clientes/newsletter.service';
import {Notificaciones} from '../../../../notificaciones';

@Component({
  selector: 'app-registrate-correo',
  templateUrl: './registrate-correo.component.html',
  styleUrls: ['./registrate-correo.component.scss']
})
export class RegistrateCorreoComponent implements OnInit {
  @ViewChild('terminosCondiciones', {static: false}) terminosCondiciones: MatCheckbox;
  opcionesSelect = [
    {
      valor: 'M',
      descripcion: 'Masculino',
    },
    {
      valor: 'F',
      descripcion: 'Femenino',
    }
  ];
  registroForm = new FormGroup({
    'email': new FormControl('', Validators.required),
    'fechaNacimiento': new FormControl('', Validators.required),
    'sexo': new FormControl('', Validators.required),
  });
  minDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<RegistrateCorreoComponent>,
    private newsletterService: NewsletterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificaciones: Notificaciones
  ) { }

  ngOnInit() {
  }

  registrarse() {
    this.notificaciones.carga('Ingresando los datos proporcionados');
    this.newsletterService.saveRegistro(this.registroForm.value).subscribe({
      error: () => this.notificaciones.error('Ya existe un registro con los datos proporcionados'),
      complete: () => this.notificaciones.exito('Se ha realizado el registro').then(() => this.dialogRef.close())
    });
  }
}
