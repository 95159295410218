import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlProductos + 'catalogos/categoria/ecommerce';
  }

  get(): Observable<any> {
    return this.http.get(this.url);
  }
}
