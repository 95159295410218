import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactanosService} from '../../../core/services/contactanos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-llama-form',
  templateUrl: './llama-form.component.html',
  styleUrls: ['./llama-form.component.scss']
})
export class LlamaFormComponent implements OnInit {

  llamaForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<LlamaFormComponent>, private fb: FormBuilder,
              private contactanosService: ContactanosService) {
    this.llamaForm = fb.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required, Validators.pattern(/^[1-9]\d{1,10}$/)]],
      correo: ['', [Validators.required, Validators.email]],
      mensaje: [''],
      idEmpresa: [1]
    });
  }

  ngOnInit() {
  }

  cancel() {
    this.llamaForm.reset();
    this.dialogRef.close();
  }

  save() {
    this.contactanosService.save(this.llamaForm.value).subscribe(data => {
        Swal.fire({
          icon: 'success',
          title: 'Felicidades se ha completado tu registro'
        }).then(result => {
          if (result) {
            window.location.reload();
          }
        });
      }
    );
  }

}
