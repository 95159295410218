import {Component, OnInit} from '@angular/core';
import {CategoriasService} from '../../../../core/services/nuevaLogica/productos/categorias.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categorias: any[];

  constructor(private categoriasService: CategoriasService) {}

  ngOnInit() {
    this.getCategorias();
  }

  getCategorias() {
    this.categoriasService.get().subscribe(result => this.categorias = result);
  }

}
