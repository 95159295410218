import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "src/app/layout/header/widgets/login/login.component";
import { QuestionFormComponent } from "../question-form/question-form.component";
import { MatDialogConfig } from "@angular/material";

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent {
  isMobile: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.resizeScreen();
  }

  resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 950px)"])
      .subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
      });
  }
  register() {
    this.dialog.open(LoginComponent, {
      minWidth: '400px'
    })
  }

  openStore() {
    if (navigator.userAgent.indexOf("Android") != -1) {
      window.open("https://play.google.com/store/apps/details?id=com.DesarrollaTech.SneakersStore&pli=1");
    }

    if (navigator.userAgent.indexOf("like Mac") != -1) {
      window.open("https://apps.apple.com/us/app/sneaker-store/id6447260033");
    }
  }
  openRecommendation() {
    this.dialog.open(QuestionFormComponent, {
      width: '350px',
      height: '350px',
      position: { right: '0px', top: '0px' },
      hasBackdrop: true,
      panelClass: 'right-slide-modal'
    });
  }



}
