import { Component, Input, OnDestroy, OnInit } from "@angular/core";
@Component({
  selector: "app-slider-images",
  templateUrl: "./slider-images.component.html",
  styleUrls: ["./slider-images.component.scss"],
})
export class SliderImagesComponent implements OnInit, OnDestroy {
  @Input() images: [] = [];
  @Input() name: string = "";
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
