import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CotzacionesService} from '../../../core/services/cotzaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cotiza-form',
  templateUrl: './cotiza-form.component.html',
  styleUrls: ['./cotiza-form.component.scss']
})
export class CotizaFormComponent {
  cotizacionForm: FormGroup;
  filedata: File;
  myFormData = new FormData();

  constructor(public dialogRef: MatDialogRef<CotizaFormComponent>, private fb: FormBuilder,
              private cotizacionService: CotzacionesService) {
    this.cotizacionForm = fb.group({
      nombre: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      email: ['', [Validators.required, Validators.email]],
      descripcion: ['', [Validators.required]],
      foto: [''],
      telefono: ['', [Validators.required, Validators.pattern(/^[1-9]\d{1,10}$/)]],
      idEmpresa: [1]
    });
  }

  registrarCotizacion() {
    this.myFormData.append('nombre', this.cotizacionForm.get('nombre').value);
    this.myFormData.append('email', this.cotizacionForm.get('email').value);
    this.myFormData.append('descripcion', this.cotizacionForm.get('descripcion').value);
    this.myFormData.append('telefono', this.cotizacionForm.get('telefono').value);
    this.myFormData.append('idEmpresa', this.cotizacionForm.get('idEmpresa').value);
    this.cotizacionService.saveCotizacion(this.myFormData).subscribe(data => {
      Swal.fire({
        icon: 'success',
        title: 'Se ha mandado tu cotización, obtendras respuesta en 72 hrs'
      }).then(result => {
        if (result) {
          window.location.reload();
        }
      });
    });
  }

  readUrl(event: any) {
    // Image upload
    this.filedata = event.target.files[0];
    this.myFormData.append('image', this.filedata, this.filedata.name);
    // this.productForm.controls.imagenes.setValue(localStorage.getItem('imagenes'));
    // console.log(this.productForm.controls.imagenes.value);
  }

}
