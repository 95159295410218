import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProspectService {
    url: string;

    constructor(private http: HttpClient) {
        this.url = environment.apiUrlProspects;
    }

    addProspect(prospect) {
        console.log(prospect);
        return this.http.post(this.url + "add-prospect", prospect);
    } 

    addProspectProduct(body) {
        return this.http.post(this.url + "add-prospect-product", body);
    } 

    getProspect(prospectId:number) {
        return this.http.get(this.url + "get-prospect/" + prospectId);
    } 
}