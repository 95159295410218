import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit {
  currentStep = 0;
  userResponses: any = [];
  steps = [1, 2, 3, 4, 5, 6]; // Ajusta según el número de pasos/preguntas
  questions = [
    { question: '¿Con qué frecuencia planeas usar estos tenis?', options: ['Diariamente', 'Semanalmente', 'Ocasionalmente'] },
    { question: '¿Para qué actividad principal necesitas los tenis?', options: ['Correr', 'Caminar', 'Deporte', 'Casual', 'Trabajo', 'Eventos'] },
    { question: '¿Tienes alguna marca de tenis preferida?', options: ['Nike', 'Adidas', 'Puma', 'Reebok', 'Sin Preferencia'] },
    { question: '¿Cuál es tu rango de presupuesto para estos tenis?', options: ['Menos de $1,000', '$10,000 - $10,001', 'Más de $20,000'] },
    { question: '¿Qué diseño prefieres?', options: ['Sencillo', 'Llamativo', 'Deportivo', 'Casual'] },
    { question: 'Es para', options: ['Hombre', 'Mujer', 'Sin Preferencia'] },
    { question: '¿Es para una ocasión especial?', options: ['Cumpleaños', 'Navidad', 'Aniversario', 'Escribe tu respuesta'] }
  ];

  get currentQuestion() {
    return this.questions[this.currentStep];
  }

  constructor() { }

  ngOnInit() { }

  selectOption(option: string) {
    this.userResponses[this.currentStep] = option;
    console.log(`Opción seleccionada para ${this.currentQuestion.question}: ${option}`);
  }

  goToNextStep() {
    if (this.currentStep < this.questions.length - 1) {
      this.currentStep++;
    } else {
      // Aquí puedes enviar las respuestas a la API
      console.log('Respuestas del usuario:', this.userResponses);
    }
  }

  goToPreviousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  closeModal() {
    // Lógica para cerrar el modal
    console.log('Modal cerrado');
  }
}
