import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserStoreService {
    url: any;
    token: any;

    constructor(private http: HttpClient, private toastrService: ToastrService) {
        this.url = environment.apiUrl;
        this.token = sessionStorage.getItem('token');
    }

    registerUser(user, tipo?: String): Observable<any> {
        return this.http.post<any>(this.url + 'userStore/' + tipo, user);
    }

    login(user): Observable<any> {
        return this.http.post<any>(this.url + 'loginStore', user).pipe(
            map(
                (userData) => {
                    const tokenStr = 'Bearer ' + userData.token;
                    sessionStorage.setItem('token', userData.token);
                    return userData;
                },
                (error) => {
                    this.toastrService.warning('error');
                }
            )
        );
    }

    validToken() {
        const now = Date.now().valueOf() / 1000;
        if (this.token != null) {
            const jwt = jwt_decode(this.token);
            return now < jwt.exp;
        } else {
            return false;
        }
    }

    getUser(): Observable<any> {
        const jwt = jwt_decode(this.token);
        return this.http.get<any>(this.url + 'getUserStore/' + jwt.sub);
    }

    getUserId(): number {
      const jwt = jwt_decode(this.token);
      return +jwt.sub;
    }

    getDatosEnvio(): Observable<any> {
        const jwt = jwt_decode(this.token);
        return this.http.get<any>(this.url + 'getDatosEnvio/' + jwt.sub);
    }

    getDatosEnvioParaActualizar(idCliente): Observable<any> {
      return this.http.get<any>(this.url + 'getDatosEnvio/' + idCliente);
    }

    getDireccion(id): Observable<any> {
        return this.http.get<any>(this.url + 'getDireccion/' + id);
    }

    getDatosByTelefono(telefono): Observable<any> {
        return this.http.get<any>(this.url + `invitados/get-by-telefono/${telefono}`);
    }

    putClienteInvitado(data): Observable<any> {
      return this.http.put<any>(this.url + `invitados`, data);
    }

    saveDatosEnvio(datosEnvio): Observable<any> {
        return this.http.post<any>(this.url + 'saveDatosEnvio', datosEnvio);
    }

    updateDatosEnvio(datosEnvio): Observable<any> {
        return this.http.put<any>(this.url + 'updateDatosEnvio', datosEnvio);
    }

    saveInvitado(invitado): Observable<any> {
        return this.http.post<any>(this.url + 'saveInvitado', invitado);
    }

    updateUser(datosUsuario): Observable<any> {
        return this.http.put<any>(this.url + 'updateUser', datosUsuario);
    }

    getCupon(id) {
        return this.http.get<any>(this.url + `codigo/${id}`);
    }

    getDescuento(id): Observable<any> {
        return this.http.get(this.url + 'beneficts-nuevo1/' + id);
    }

    forgotPass(mail): Observable<any> {
        return this.http.post('https://backend.sneakerstore.com.mx/nueva-base/clientes/usuarios/forgotPasswordMail', mail);
    }

    bajaDatosEnvio(id): Observable<any> {
        return this.http.put(this.url + 'bajaDatosEnvio/' + id, id);
    }
}
