import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Marca } from 'src/app/core/interfaces/productos/catalogos/Marca';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarcasService {
  url: string;
  baseURL: string;
  urlProd: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrlProductos;
    this.url = this.baseURL + 'catalogos/marcas';
    this.urlProd = environment.apiUrlBack;
  }

  get(): Observable<any> {
    return this.http.get(`${this.url}/activas`);
  }

  getMarcasSubmarcas(): Observable<any> {
    return this.http.get<any[]>(`${this.urlProd}/productos/catalogos/marcas-submarcas`);
  }
}
