import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { CarritoService } from "src/app/core/services/nuevaLogica/checkout/carrito.service";
import { AddProductOfferComponent } from "../../productos/modal/add-product-offer/add-product-offer.component";
import { LoginComponent } from "src/app/layout/header/widgets/login/login.component";
import { FavoritesService } from "src/app/core/services/favorites.service";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { SuccessSSToast } from "src/app/core/toast/success-ss-toast.component";
import { UserStoreService } from "src/app/core/services/user-store.service";
import { UserstoreService } from "src/app/core/services/nuevaLogica/clientes/userstore.service";
import { CompareService } from 'src/app/core/services/compare-service.service';

@Component({
  selector: "app-home-shoes-slider",
  templateUrl: "./home-shoes-slider.component.html",
  styleUrls: ["./home-shoes-slider.component.scss"],
})
export class HomeShoesSliderComponent implements OnInit {
  _shoes: any[] = [];
  logged = false;
  @Input() small: boolean;
  @Input() xsmall: boolean;
  @Input() widthFav: string = "";

  @Input() descuento: boolean;
  @Input() fromSubasta?: boolean;

  options: GlobalConfig;

  // Input() setter
  @Input() set shoes(shoes: any[]) {
    this._shoes = shoes;
    if (!shoes || shoes.length === 0) return;
    this.checkFavorites(this.favoritesService.favoritesSaved.getValue(), shoes);
  }

  constructor(
    private router: Router,
    private carritoService: CarritoService,
    private dialog: MatDialog,
    private favoritesService: FavoritesService,
    private toastrService: ToastrService,
    private compareService: CompareService,
    private userService: UserstoreService
  ) {}

  ngOnInit() {
    this.logged = this.userService.validToken();
    this.favoritesSaved();
  }

  clickButton(shoe) {
    if (this.descuento == true) {
      this.addCartLogic(shoe);
    } else if (this.fromSubasta == true) {
      this.openModalAddOffer(shoe);
    } else {
      this.goToLink(shoe);
    }
  }

  goToLink(product) {
    sessionStorage.setItem("producto_descuento_talla", product.talla);
    window.location.href = "/productos/detalle/" + product.descripcionURL;
  }

  private addCartLogic(product) {
    this.carritoService.updateCarrito({
      precio: this.getPrecioVenta(product),
      nombre: product.nombre,
      idTallaSeleccionada: product.idTallaProducto,
      talla: product.talla,
      imagen: product.imagen,
      cantidad: 1,
      idDescuento: product.idDescuento,
      idTipoDescuento: product.idTipoDescuento,
      descuento: product.descuento,
    });

    sessionStorage.removeItem("orderType");
    this.router.navigate(["/checkout/pago"]);
  }

  getPrecioVenta(producto): number {
    if (producto.idTipoDescuento != null) {
      if (producto.idTipoDescuento === 1) {
        return (
          producto.precioVenta -
          (producto.precioVenta * producto.descuento) / 100
        );
      }

      return producto.precioVenta - producto.descuento;
    }

    return producto.precioVenta;
  }

  openModalAddOffer(product) {
    if (
      sessionStorage.getItem("token") != null ||
      sessionStorage.getItem("token") != undefined
    ) {
      this.dialog
        .open(AddProductOfferComponent, {
          data: {
            subastaProductoId: product.subastaProductoId,
          },
          width: "550px",
        })
        .afterClosed()
        .subscribe((reload) => {
          if (reload) {
          }
        });
    } else {
      this.dialog.open(LoginComponent, {
        width: "400px",
      });
    }
  }

  private favoritesSaved() {
    this.favoritesService.$favoritesSaved.subscribe((favorites) => {
      // add isFavorite to shoes found into favorites
      if (!this._shoes || this._shoes.length === 0) return;
      this.checkFavorites(favorites, this._shoes);
    });
  }

  private checkFavorites(saved, current): void {
    this._shoes = current.map((shoe) => {
      const found = saved.find(
        (favorite) => favorite.idProducto === shoe.idProducto
      );
      if (found) {
        return { ...shoe, isFavorite: true };
      }
      return shoe;
    });
  }

  addFavorite(event, item): void {
    event.stopPropagation();
    console.log(item);

    if (!item.isFavorite) {
      this.favoritesService.addFavorite(item.idProducto).subscribe({
        next: (response) => {
          console.log(response);
          item.isFavorite = true;
          this.loadFavorites();
          this.openToast("¡Producto agregado a favoritos con éxito!");
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.favoritesService.deleteFavorite(this.getIdFavorito(item.idProducto)).subscribe({
        next: (response) => {
          console.log(response);
          item.isFavorite = false;
          this.openToast("¡Producto eliminado de favoritos con éxito!");
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  private openToast(title: string): void {
    this.toastrService.toastrConfig.toastComponent = SuccessSSToast;
    this.toastrService.toastrConfig.toastClass = "sstoast";
    const inserted = this.toastrService.show(title, "", this.options);
  }

  get minWidth() {
    if (this.small) {
      return "231px";
    } else if (this.xsmall) {
      return "118px";
    } else {
      return "392px";
    }
  }

  loadFavorites() {
    this.favoritesService.getFavorites()
    .subscribe(data => {
      this.favoritesService.cargaFavoritos(data);
    });
  }

  getIdFavorito(idProducto): number {
    let id = 0;
    let product;
    this.favoritesService.$favoritesSaved.subscribe(data => {
      product = data.find(i => i.idProducto == idProducto);
      id = product.idProductoFavorito;
    });
    return id;
  }

  Compare(event, item): void {
    event.stopPropagation();
    this.compareService.addProducto({
      id: item.idProducto.toString(),
      nombre: item.nombre,
      imagen: item.imagen,
      idTallaProducto: item.idTallaProducto,
      talla: item.talla,
      idDescuento: item.idDescuento,
      idTipoDescuento: item.idTipoDescuento,
      descuento: item.descuento,
      precioVenta: item.precioVenta,
      descripcionURL: item.descripcionURL
    });
  }
}
