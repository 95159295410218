import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parsearImagen',
})
export class ParsearImagenPipe implements PipeTransform {
  transform(url: string): any {
    if (url.length > 0) {
      if (url.includes(',')) {
        const imagenArreglo = url.replace(/[\\"\[\]]/g, '');
        return imagenArreglo.split(',')[0];
      } else {
        return url.replace(/[\\"\[\]]/g, '');
      }
    } else {
      return '';
    }
  }
}
