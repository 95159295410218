import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MarcasService {
  url: any;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlBack;
  }

  public get(): Observable<any[]> {
    return this.http.get<any[]>(
      this.url + "/productos/catalogos/marcas-submarcas"
    );
  }

  public getByIdEmpresa(): Observable<any[]> {
    return this.http.get<any[]>(this.url + "marcas/id-empresa/1");
  }

  public getMarcaByCategoria(idCategoria): Observable<any[]> {
    return this.http.get<any[]>(this.url + "marcaByCategoria/" + idCategoria);
  }
}
