import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component } from "@angular/core";

interface Guide {
  name: string;
  source: string;
  keys: string;
  columns: string;
  mobile: string;
}

@Component({
  selector: "app-guia-tallas",
  templateUrl: "./guia-tallas.component.html",
  styleUrls: ["./guia-tallas.component.scss"],
})
export class GuiaTallasComponent {
  btnGuides: Guide[] = [
    {
      name: "Hombre",
      source: "dataSourceHombre",
      keys: "columnasKeysHombre",
      columns: "columnasHombre",
      mobile: "columnasHombreMobile",
    },
    {
      name: "Mujer",
      source: "dataSourceMujer",
      keys: "columnasKeysMujer",
      columns: "columnasMujer",
      mobile: "columnasMujerMobile",
    },
    {
      name: "Niño",
      source: "dataSourceN",
      keys: "columnasKeysNino",
      columns: "columnasNino",
      mobile: "columnasNino",
    },
    // {
    //   name: "Niño pequeño",
    //   source: "dataSourceNinoP",
    //   keys: "columnasKeysNino",
    //   columns: "columnasNino",
    //   mobile: "columnasNino",
    // },
    // {
    //   name: "Bebé e infantil",
    //   source: "dataSourceBebe",
    //   keys: "columnasKeysNino",
    //   columns: "columnasNino",
    //   mobile: "columnasNino",
    // },
  ];

  columnasHombre = ["MX", "EE.UU.- Hombre", "EE.UU.- Mujer", "R.U.", "UE"];
  columnasHombreMobile = ["MX", "USA/H", "USA/M", "R.U.", "UE"];
  columnasKeysHombre = ["mexico", "euHombre", "euMujer", "ru", "ue"];

  dataSourceHombre = [
    { mexico: 22.5, euHombre: 3.5, euMujer: 5, ru: 3, ue: 35.5 },
    { mexico: 23, euHombre: 4, euMujer: 5.5, ru: 3.5, ue: 36 },
    { mexico: 23.5, euHombre: 4.5, euMujer: 6, ru: 4, ue: 36.5 },
    { mexico: 23.5, euHombre: 5, euMujer: 6.5, ru: 4.5, ue: 37.5 },
    { mexico: 24, euHombre: 5.5, euMujer: 7, ru: 5, ue: 38 },
    { mexico: 24, euHombre: 6, euMujer: 7.5, ru: 5.5, ue: 38.5 },
    { mexico: 24.5, euHombre: 6.5, euMujer: 8, ru: 6, ue: 39 },
    { mexico: 25, euHombre: 7, euMujer: 8.5, ru: 6, ue: 40 },
    { mexico: 25.5, euHombre: 7.5, euMujer: 9, ru: 6.5, ue: 40.5 },
    { mexico: 26, euHombre: 8, euMujer: 9.5, ru: 7, ue: 41 },
    { mexico: 26.5, euHombre: 8.5, euMujer: 10, ru: 7.5, ue: 42 },
    { mexico: 27, euHombre: 9, euMujer: 10.5, ru: 8, ue: 42.5 },
    { mexico: 27.5, euHombre: 9.5, euMujer: 11, ru: 8.5, ue: 43 },
    { mexico: 28, euHombre: 10, euMujer: 11.5, ru: 9, ue: 44 },
    { mexico: 28.5, euHombre: 10.5, euMujer: 12, ru: 9.5, ue: 44.5 },
    { mexico: 29, euHombre: 11, euMujer: 12.5, ru: 9, ue: 45 },
    { mexico: 29.5, euHombre: 11.5, euMujer: 13, ru: 9.5, ue: 45.5 },
    { mexico: 30, euHombre: 12, euMujer: 13.5, ru: 10, ue: 46 },
    { mexico: 30.5, euHombre: 12.5, euMujer: 14, ru: 10.5, ue: 47 },
    { mexico: 31, euHombre: 13, euMujer: 14.5, ru: 11, ue: 47.5 },
    { mexico: 31.5, euHombre: 13.5, euMujer: 15, ru: 11.5, ue: 48 },
    { mexico: 32, euHombre: 14, euMujer: 15.5, ru: 13, ue: 48.5 },
  ];

  columnasMujer = ["MX", "EE.UU.- Mujer", "EE.UU.- Hombre	", "R.U.", "UE"];
  columnasMujerMobile = ["MX", "USA/M", "USA/H", "R.U.", "UE"];
  columnasKeysMujer = ["mexico", "euMujer", "euHombre", "ru", "ue"];

  dataSourceMujer = [
    { mexico: 21, euMujer: 4, euHombre: 2.5, ru: 1.5, ue: 34.5 },
    { mexico: 21.5, euMujer: 4.5, euHombre: 3, ru: 2, ue: 35 },
    { mexico: 22, euMujer: 5, euHombre: 3.5, ru: 2.5, ue: 35.5 },
    { mexico: 22.5, euMujer: 5.5, euHombre: 4, ru: 3, ue: 36 },
    { mexico: 23, euMujer: 6, euHombre: 4.5, ru: 3.5, ue: 36.5 },
    { mexico: 23.5, euMujer: 6.5, euHombre: 5, ru: 4, ue: 37.5 },
    { mexico: 24, euMujer: 7, euHombre: 5.5, ru: 4.5, ue: 38 },
    { mexico: 24.5, euMujer: 7.5, euHombre: 6, ru: 5, ue: 38.5 },
    { mexico: 25, euMujer: 8, euHombre: 6.5, ru: 5.5, ue: 39 },
    { mexico: 25.5, euMujer: 8.5, euHombre: 7, ru: 6, ue: 40 },
    { mexico: 26, euMujer: 9, euHombre: 7.5, ru: 6.5, ue: 40.5 },
    { mexico: 26.5, euMujer: 9.5, euHombre: 8, ru: 7, ue: 41 },
    { mexico: 27, euMujer: 10, euHombre: 8.5, ru: 7.5, ue: 42 },
    { mexico: 27.5, euMujer: 10.5, euHombre: 9, ru: 8, ue: 42.5 },
    { mexico: 28, euMujer: 11, euHombre: 9.5, ru: 8.5, ue: 43 },
    { mexico: 28.5, euMujer: 11.5, euHombre: 10, ru: 9, ue: 44 },
    { mexico: 29, euMujer: 12, euHombre: 10.5, ru: 9.5, ue: 44.5 },
    { mexico: 29.5, euMujer: 12.5, euHombre: 11, ru: 10, ue: 45 },
    { mexico: 30, euMujer: 13, euHombre: 11.5, ru: 10.5, ue: 45.5 },
    { mexico: 30.5, euMujer: 13.5, euHombre: 12, ru: 11, ue: 46 },
    { mexico: 31, euMujer: 14, euHombre: 12.5, ru: 11.5, ue: 47 },
    { mexico: 31.5, euMujer: 14.5, euHombre: 13, ru: 12, ue: 47.5 },
    { mexico: 32, euMujer: 15, euHombre: 13.5, ru: 12.5, ue: 48 },
  ];

  columnasNino = ["MX", "EE.UU. - Niño", "R.U.", "UE"];
  columnasKeysNino = ["mexico", "euNino", "ru", "ue"];

  dataSourceNino = [
    { mexico: 22.5, euNino: "3.5Y", ru: 3, ue: 35.5 },
    { mexico: 23, euNino: "4Y", ru: 3.5, ue: 36 },
    { mexico: 23.5, euNino: "4.5Y", ru: 4, ue: 36.5 },
    { mexico: 23.5, euNino: "5Y", ru: 4.5, ue: 37.5 },
    { mexico: 24, euNino: "5.5Y", ru: 5, ue: 38 },
    { mexico: 24, euNino: "6Y", ru: 5.5, ue: 38.5 },
    { mexico: 24.5, euNino: "6.5Y", ru: 6, ue: 39 },
    { mexico: 25, euNino: "7Y", ru: 6, ue: 40 },
  ];

  dataSourceNinoP = [
    { mexico: 16.5, euNino: "10.5C", ru: 10, ue: 27.5 },
    { mexico: 17, euNino: "11C", ru: 10.5, ue: 28 },
    { mexico: 17.5, euNino: "11.5C", ru: 11, ue: 28.5 },
    { mexico: 18, euNino: "12C", ru: 11.5, ue: 29.5 },
    { mexico: 18.5, euNino: "12.5C", ru: 12, ue: 30 },
    { mexico: 19, euNino: "13C", ru: 12.5, ue: 31 },
    { mexico: 19.5, euNino: "13.5C", ru: 13, ue: 31.5 },
    { mexico: 20, euNino: "1Y", ru: 13.5, ue: 32 },
    { mexico: 20.5, euNino: "1.5Y", ru: 1, ue: 33 },
    { mexico: 21, euNino: "2Y", ru: 1.5, ue: 33.5 },
    { mexico: 21.5, euNino: "2.5Y", ru: 2, ue: 34 },
    { mexico: 22, euNino: "3Y", ru: 2.5, ue: 35 },
  ];

  dataSourceBebe = [
    { mexico: 7, euNino: "1C", ru: 0.5, ue: 16 },
    { mexico: 7.5, euNino: "1.5C", ru: 1, ue: 16.5 },
    { mexico: 8, euNino: "2C", ru: 1.5, ue: 17 },
    { mexico: 8.5, euNino: "2.5C", ru: 2, ue: 18 },
    { mexico: 9, euNino: "3C", ru: 2.5, ue: 18.5 },
    { mexico: 9.5, euNino: "3.5C", ru: 3, ue: 19 },
    { mexico: 10, euNino: "4", ru: 3.5, ue: 19.5 },
    { mexico: 10.5, euNino: "4.5C", ru: 4, ue: 20 },
    { mexico: 11, euNino: "5C", ru: 4.5, ue: 21 },
    { mexico: 11.5, euNino: "5.5C", ru: 5, ue: 21.5 },
    { mexico: 12, euNino: "6C", ru: 5.5, ue: 22 },
    { mexico: 12.5, euNino: "6.5C", ru: 6, ue: 22.5 },
    { mexico: 13, euNino: "7C", ru: 6.5, ue: 23.5 },
    { mexico: 13.5, euNino: "7.5C", ru: 7, ue: 24 },
    { mexico: 14, euNino: "8", ru: 7.5, ue: 25 },
    { mexico: 14.5, euNino: "8.5C", ru: 8, ue: 25.5 },
    { mexico: 15, euNino: "9C", ru: 8.5, ue: 26 },
    { mexico: 15.5, euNino: "9.5C", ru: 9, ue: 26.5 },
    { mexico: 16, euNino: "10C", ru: 9.5, ue: 27 },
  ];

  dataSourceN = [
    { mexico: 7, euNino: "1C", ru: 0.5, ue: 16 },
    { mexico: 7.5, euNino: "1.5C", ru: 1, ue: 16.5 },
    { mexico: 8, euNino: "2C", ru: 1.5, ue: 17 },
    { mexico: 8.5, euNino: "2.5C", ru: 2, ue: 18 },
    { mexico: 9, euNino: "3C", ru: 2.5, ue: 18.5 },
    { mexico: 9.5, euNino: "3.5C", ru: 3, ue: 19 },
    { mexico: 10, euNino: "4", ru: 3.5, ue: 19.5 },
    { mexico: 10.5, euNino: "4.5C", ru: 4, ue: 20 },
    { mexico: 11, euNino: "5C", ru: 4.5, ue: 21 },
    { mexico: 11.5, euNino: "5.5C", ru: 5, ue: 21.5 },
    { mexico: 12, euNino: "6C", ru: 5.5, ue: 22 },
    { mexico: 12.5, euNino: "6.5C", ru: 6, ue: 22.5 },
    { mexico: 13, euNino: "7C", ru: 6.5, ue: 23.5 },
    { mexico: 13.5, euNino: "7.5C", ru: 7, ue: 24 },
    { mexico: 14, euNino: "8", ru: 7.5, ue: 25 },
    { mexico: 14.5, euNino: "8.5C", ru: 8, ue: 25.5 },
    { mexico: 15, euNino: "9C", ru: 8.5, ue: 26 },
    { mexico: 15.5, euNino: "9.5C", ru: 9, ue: 26.5 },
    { mexico: 16, euNino: "10C", ru: 9.5, ue: 27 },
    { mexico: 16.5, euNino: "10.5C", ru: 10, ue: 27.5 },
    { mexico: 17, euNino: "11C", ru: 10.5, ue: 28 },
    { mexico: 17.5, euNino: "11.5C", ru: 11, ue: 28.5 },
    { mexico: 18, euNino: "12C", ru: 11.5, ue: 29.5 },
    { mexico: 18.5, euNino: "12.5C", ru: 12, ue: 30 },
    { mexico: 19, euNino: "13C", ru: 12.5, ue: 31 },
    { mexico: 19.5, euNino: "13.5C", ru: 13, ue: 31.5 },
    { mexico: 20, euNino: "1Y", ru: 13.5, ue: 32 },
    { mexico: 20.5, euNino: "1.5Y", ru: 1, ue: 33 },
    { mexico: 21, euNino: "2Y", ru: 1.5, ue: 33.5 },
    { mexico: 21.5, euNino: "2.5Y", ru: 2, ue: 34 },
    { mexico: 22, euNino: "3Y", ru: 2.5, ue: 35 },
    { mexico: 22.5, euNino: "3.5Y", ru: 3, ue: 35.5 },
    { mexico: 23, euNino: "4Y", ru: 3.5, ue: 36 },
    { mexico: 23.5, euNino: "4.5Y", ru: 4, ue: 36.5 },
    { mexico: 23.5, euNino: "5Y", ru: 4.5, ue: 37.5 },
    { mexico: 24, euNino: "5.5Y", ru: 5, ue: 38 },
    { mexico: 24, euNino: "6Y", ru: 5.5, ue: 38.5 },
    { mexico: 24.5, euNino: "6.5Y", ru: 6, ue: 39 },
    { mexico: 25, euNino: "7Y", ru: 6, ue: 40 },
  ];

  columnas = [];
  datasource = [];
  keys = [];
  currentIndex: number = null;

  isMobile: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.resizeScreen();
  }

  private resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 600px)"])
      .subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
        if (this.currentIndex === null) return;
        this.columnas = result.matches
          ? this[this.btnGuides[this.currentIndex].mobile]
          : this[this.btnGuides[this.currentIndex].columns];
      });
  }

  selectSize(guide: Guide, i: number) {
    if (this.currentIndex === i) return (this.currentIndex = null);
    this.currentIndex = i;
    this.datasource = this[guide.source];
    this.keys = this[guide.keys];
    this.columnas = this.isMobile
      ? this[this.btnGuides[i].mobile]
      : this[this.btnGuides[i].columns];
  }
}
