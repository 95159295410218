import { Component, Input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { RegistrateCorreoComponent } from "./modals/registrate-correo/registrate-correo.component";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { NewsletterService } from "src/app/core/services/nuevaLogica/clientes/newsletter.service";
import { Subscription } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { Notificaciones } from 'src/app/notificaciones';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnDestroy {
  isSimplified: boolean;
  url: string;

  simplifiedRoutes = [
    "/landing-jordan",
    "/productos/verificacion-pedido",
    "/atencion-clientes/cambio-productos",
    "/atencion-clientes/abono-apartado"
  ];
  subs = new Subscription();
  ctrlMail = new FormControl("", [Validators.required, Validators.email]);
  disableBtn = false;
  year;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private newsletterService: NewsletterService,
    private notificaciones: Notificaciones
  ) {
    this.listenRoute();
    this.year = new Date().getFullYear();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private listenRoute(): void {
    this.router.events
      .pipe(filter((x) => x instanceof NavigationEnd))
      .subscribe((val) => {
        this.url = val["url"];

        if (this.url === "/") return (this.isSimplified = true);

        for (const url of this.simplifiedRoutes) {
          if (this.url.indexOf(url) !== -1) {
            this.isSimplified = true;
            return;
          } else {
            this.isSimplified = false;
          }
        }
      });
  }

  modalRegistro() {
    this.dialog.open(RegistrateCorreoComponent, {
      width: "400px",
      height: "500px",
    });
  }

  subscribeNewsletter(): void {
    this.disableBtn = true;
    this.subs = this.newsletterService
      .subscribeNewsletter(this.ctrlMail.value)
      .subscribe({
        next: (res) => {
          console.log(res);
        },
        complete: () => {
          this.disableBtn = false;
          this.ctrlMail.reset();
          this.mostrarMensaje('Fuiste registrado');
        },
      });
  }

  mostrarMensaje(mensaje: string): void {
    this.notificaciones.exito(mensaje);
  }
}
