import {Component, OnInit} from '@angular/core';
import {UserstoreService} from '../../../core/services/nuevaLogica/clientes/userstore.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-btn-cupon',
  templateUrl: './btn-cupon.component.html',
  styleUrls: ['./btn-cupon.component.scss']
})
export class BtnCuponComponent implements OnInit {
  cuponText: string;

  constructor(
    private _snackBar: MatSnackBar,
    private userService: UserstoreService) { }

  ngOnInit() {
    this.getCupon();
  }

  // Copy Text Cupon
  copyText(input) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    this._snackBar.open('Cupón copiado', 'Cerrar', {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['mat-toolbar', 'mat-warn']
    });
  }

  getCupon() {
    this.userService.getCodigo().subscribe((dataCupon) => this.cuponText = dataCupon.codigo);
  }
}
