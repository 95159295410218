import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
  numerosWhatsApp = [
    '5215529101196'
    // '525535098214'
  ];

  constructor(
    public dialogRef: MatDialogRef<ContactFormComponent>,
  ) { }

  abrirWhatsapp(numero: string) {
    window.open(
      'https://api.whatsapp.com/send?phone=+' + numero +
          '&text=Hola%20requiero%20informes%20de%20un%20producto%20del%20ecommerce', '_blank'
    );
    this.dialogRef.close();
  }
}
