import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-apartado',
  templateUrl: './apartado.component.html',
  styleUrls: ['./apartado.component.scss']
})
export class ApartadoComponent implements OnInit {

  ctrlMonto = new FormControl(0, [Validators.required, Validators.min(1000)]);
  max: number;

  requirementList = [
    "Se calcula automáticamente el 30% y el monto mínimo de pago es de $1,000",
    "Puedes ajustar el monto a tus necesiades y este será tu primer pago",
    "Cuentas con 60 días naturales para liquidar tu par",
    "Liquida antes del lapso de tiempo y tu par será enviado con anticipación",
  ];

  constructor(
    public dialogRef: MatDialogRef<ApartadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.max = this.data.precio;
  }

  ngOnInit() {
    this.ctrlMonto.setValue(this.initialPrice());
  }

  cerrar() {
    this.dialogRef.close();
  }

  rangeOnChange(ev): void {
    this.ctrlMonto.setValue(ev);
  }

  get getMonto() {
    const value = this.ctrlMonto.value;
    if (!!value && value > 0) return value;
    return 0;
  }

  initialPrice(): string {
    return ((this.data.precio * this.data.cantidad) * 0.3).toFixed(2)
  }

  continuar() {
    this.dialogRef.close({valido: true, monto: this.ctrlMonto.value})
  }
}
