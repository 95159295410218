import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdenesService } from 'src/app/core/services/nuevaLogica/checkout/ordenes.service';
import { Notificaciones } from 'src/app/notificaciones';

@Component({
    selector: 'app-add-product-offer',
    templateUrl: './add-product-offer.component.html',
    styleUrls: ['./add-product-offer.component.scss']
})
export class AddProductOfferComponent implements OnInit {
    inputType = 'password';
    oferta = "";
    wronOffer = false;
    formaPago= "";

    constructor(public dialogRef: MatDialogRef<AddProductOfferComponent>,
                private ordenesService: OrdenesService,
                private notificaciones: Notificaciones,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    addOffer() {
        this.notificaciones.carga("Enviando oferta");

        const request = {
            productoId: this.data.subastaProductoId,
            amountOffered: this.oferta,
            metodoPago: this.formaPago
        }

        this.ordenesService.AddSubastaProductOffer(request).subscribe({

            next: (value: any) => {

                this.notificaciones.cerrar();

                if(value.offer_accepted) {
                    this.wronOffer = false;

                    this.notificaciones.exito("Oferta enviada").then(() => {
                        this.dialogRef.close();
                    });
                }
                else {
                    this.wronOffer = true;
                }
            },
            error: () => this.notificaciones.error("Error al agregar oferta")
        });
    }
}