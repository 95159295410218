import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { LayoutModule } from "./layout/layout.module";
import { ShopModule } from "./shop/shop.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";
import { registerLocaleData } from "@angular/common";
import localeMx from "@angular/common/locales/es-MX";
import { MatMenuModule } from "@angular/material/menu";
import { PinkToast } from "./core/toast/pink-toast.component";
import { SuccessSSToast } from "./core/toast/success-ss-toast.component";

registerLocaleData(localeMx);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, PinkToast, SuccessSSToast],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    MatMenuModule,
    ShopModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      progressBar: false,
      enableHtml: true,
    }),
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "es-MX" }],
  bootstrap: [AppComponent],
  entryComponents: [PinkToast, SuccessSSToast],
})
export class AppModule {}
