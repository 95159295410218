import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpresaInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersAnteriores = this.getHeadersPrevios(req.headers.keys(), req);
    const Auth = sessionStorage.getItem('token');
    let headers;

    if (Auth) {
      headers = new HttpHeaders({
        empresa: 'ss',
        Auth,
        ...headersAnteriores
      });
    } else {
      headers = new HttpHeaders({
        empresa: 'ss',
        ...headersAnteriores
      });
    }
    const nuevaRequest = req.clone({headers});
    return next.handle(nuevaRequest);
  }

  getHeadersPrevios(headersAntiguos, req: HttpRequest<any>): any {
    const headersPrevios = {};

    if (headersAntiguos.length > 0) {
      headersAntiguos.forEach(header => headersPrevios[header] = req.headers.get(header));
    }

    return headersPrevios;
  }
}
