import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgControl, ValidationErrors } from "@angular/forms";
import { Subscription } from "rxjs";
// import { ValidationMessageService } from '../service/validation-msg.service';

@Directive({
  selector: "[appValidationInput]",
})
export class ValidationInputDirective implements OnInit {
  errors = {
    required: "Campo obligatorio",
    pattern: "Campo inválido",
    email: "Introduzca un correo electrónico válido",
    sinColonias: "No existen colonias para este código postal",
  };

  constructor(
    private elRef: ElementRef,
    private control: NgControl // private validationMsgService: ValidationMessageService
  ) {}

  @Input("formControlName") formControlName: string;
  errorSpanId = "";
  statusChangeSubscription: Subscription;

  ngOnInit(): void {
    this.errorSpanId = this.formControlName + "-error";
    this.statusChangeSubscription = this.control.statusChanges.subscribe(
      (status) => {
        if (status === "INVALID") {
          this.showError();
        } else {
          this.removeError();
        }
      }
    );
  }

  @HostListener("blur", ["$event"])
  handleBlurEvent(event) {
    console.log("this.control.value => ", this.control.value);
    // This is needed to handle the case of clicking a required field and moving out.// Rest all are handled by status change subscriptionif (this.control.value === null || this.control.value === '') {
    if (this.control.errors) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors;
    console.log(valErrors);
    const firstKey = Object.keys(valErrors)[0];
    const errorMsg = this.errors[firstKey] || "Campo inválido";
    const errSpan =
      '<span style="color: #be1622; font-size: 11px" id="' +
      this.errorSpanId +
      '">' +
      errorMsg +
      "</span>";
    this.elRef.nativeElement.parentElement.insertAdjacentHTML(
      "afterend",
      errSpan
    );
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.remove();
    }
  }
}
