import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MarcasService } from 'src/app/core/services/marcas.service';

export interface Marcas {
  activo: number;
  descripcion: string;
  fechaCreacion: string;
  id: number;
  idEmpresa: number;
}
@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.scss']
})
export class MarcasComponent implements OnInit {

  marcas: any[] = [];
  marcaSeleccionada: any;
  @Output('idMarca') idMarca = new EventEmitter();
  @Output('borrarFiltro') borrarFiltro = new EventEmitter();
  @Input('clear') clear = false;

  constructor(private marcasService: MarcasService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getMarcas();
  }

  getMarcas() {
    this.marcasService.get().subscribe((resp) => this.marcas = resp);
  }

  marcaSelected(m, id) {
    this.marcaSeleccionada = m;
    this.idMarca.emit(id);
  }

  /*openDialog() {
    const dialogRef = this.dialog.open(ModalMarcasComponent, {
      data: {marcas: this.marcas}
    });

    dialogRef.afterClosed().subscribe((marca: Marcas) => {
      marca ? this.marcaSelected(marca.descripcion, marca.id) : null
    });
  }*/

}
