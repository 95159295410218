import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Marca } from 'src/app/core/interfaces/productos/catalogos/Marca';
import { MarcasService } from 'src/app/core/services/nuevaLogica/productos/catalogos/marcas.service';
import { CategoriasService } from 'src/app/core/services/nuevaLogica/productos/categorias.service';

@Component({
  selector: 'app-filtros-productos',
  templateUrl: './filtros-productos.component.html',
  styleUrls: ['./filtros-productos.component.scss']
})
export class FiltrosProductosComponent implements OnInit {
  marcas: Marca[];
  categorias: any;
  marcaSeleccionada: string;
  categoriaSeleccionada: string;

  constructor(
    private dialogRef: MatDialogRef<FiltrosProductosComponent>,
    private marcasService: MarcasService,
    private categoriasService: CategoriasService
  ) { }

  ngOnInit() {
    this.getMarcas();
    this.getCategorias();
  }

  getMarcas() {
    this.marcasService.get().subscribe(data => this.marcas = data);
  }

  getCategorias() {
    this.categoriasService.get().subscribe(data => this.categorias = data);
  }

  filtrar() {
    console.log(this.categoriaSeleccionada);
    this.dialogRef.close({
      marca: this.marcaSeleccionada,
    });
  }

}
