import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TarjetaProductosComponent } from "./tarjeta-productos/tarjeta-productos.component";
import { RouterModule } from "@angular/router";
import { ParsearImagenPipe } from "./pipes/parsear-imagen.pipe";
import { LlamaFormComponent } from "./llama-form/llama-form.component";
import {
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatCardModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatInputModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatRadioModule,
  MatListModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CotizaFormComponent } from "./cotiza-form/cotiza-form.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { ProductSliderComponent } from "./product-slider/product-slider.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BtnCuponComponent } from "./btn-cupon/btn-cupon.component";
import { TextSizeDirective } from "./directives/text-size.directive";
import { TarjetaProductosSeleccionComponent } from "./tarjeta-productos-seleccion/tarjeta-productos-seleccion.component";
import { SeleccionarTallaComponent } from "./tarjeta-productos-seleccion/seleccionar-talla/seleccionar-talla.component";
import { FiltrosProductosComponent } from "./filtros-productos/filtros-productos.component";
import { BlogSliderComponent } from "./blog-slider/blog-slider.component";
import { AngoliaSearchComponent } from "./angolia-search/angolia-search.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MatMenuModule } from "@angular/material/menu";
import { HomeSliderPromotionsComponent } from "src/app/shop/home/home-slider-promotions/home-slider-promotions.component";
import { HomeTextosSliderComponent } from "src/app/shop/home/home-textos-slider/home-textos-slider.component";
import { HomeShoesSliderComponent } from "src/app/shop/home/home-shoes-slider/home-shoes-slider.component";
import { SliderImagesComponent } from "./slider-images/slider-images.component";
import { SliderRangeComponent } from "./slider-range/slider-range.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { BannerX2Component } from "./banner-x2/banner-x2.component";
import { OrdenEnProcesoComponent } from "./orden-en-proceso/orden-en-proceso.component";
import { MenuResponsiveComponent } from "./menu-responsive/menu-responsive.component";
import { GuiaTallasComponent } from "./guia-tallas/guia-tallas.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { HomeNoticiasSliderComponent } from "./home-noticias-slider/home-noticias-slider.component";
import { ValidationInputDirective } from "src/app/core/directives/validation-input.directive";
import { ApartadoComponent } from './apartado/apartado.component';
import { ImagenesComponent } from './imagenes/imagenes.component';
import { QuestionFormComponent } from './question-form/question-form.component';

@NgModule({
  declarations: [
    TarjetaProductosComponent,
    ParsearImagenPipe,
    LlamaFormComponent,
    CotizaFormComponent,
    ContactFormComponent,
    ProductSliderComponent,
    BlogSliderComponent,
    BtnCuponComponent,
    TextSizeDirective,
    TarjetaProductosSeleccionComponent,
    SeleccionarTallaComponent,
    FiltrosProductosComponent,
    AngoliaSearchComponent,
    HomeSliderPromotionsComponent,
    HomeTextosSliderComponent,
    HomeShoesSliderComponent,
    SliderImagesComponent,
    SliderRangeComponent,
    CheckboxComponent,
    BannerX2Component,
    OrdenEnProcesoComponent,
    MenuResponsiveComponent,
    GuiaTallasComponent,
    TopBarComponent,
    HomeNoticiasSliderComponent,
    ValidationInputDirective,
    ApartadoComponent,
    ImagenesComponent,
    QuestionFormComponent,
  ],
  exports: [
    TarjetaProductosComponent,
    ParsearImagenPipe,
    ProductSliderComponent,
    BlogSliderComponent,
    BtnCuponComponent,
    TarjetaProductosSeleccionComponent,
    FiltrosProductosComponent,
    AngoliaSearchComponent,
    HomeSliderPromotionsComponent,
    HomeTextosSliderComponent,
    HomeShoesSliderComponent,
    SliderImagesComponent,
    SliderRangeComponent,
    CheckboxComponent,
    BannerX2Component,
    OrdenEnProcesoComponent,
    MenuResponsiveComponent,
    GuiaTallasComponent,
    TopBarComponent,
    AngoliaSearchComponent,
    HomeNoticiasSliderComponent,
    ValidationInputDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    SlickCarouselModule,
    MatCardModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FormsModule,
    MatExpansionModule,
    MatRadioModule,
    MatListModule,
    NgxPaginationModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  entryComponents: [
    LlamaFormComponent,
    CotizaFormComponent,
    ContactFormComponent,
    SeleccionarTallaComponent,
    FiltrosProductosComponent,
    ApartadoComponent,
    ImagenesComponent,
    QuestionFormComponent
  ],
})
export class GeneralesModule {}
