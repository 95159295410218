import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Notificaciones } from 'src/app/notificaciones';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.scss']
})
export class ImagenesComponent implements OnInit {

  arrImagenes: any[] = [];
  contador = 0;
  data: any;
  viewAprobar: boolean = true;
  titulo = 'RECIBOS DE PAGO';
  viewDocs: boolean = false;
  viewGastos: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImagenesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataUrl: any,
    private notificaciones: Notificaciones,
    private sanitizer: DomSanitizer
  ) {
    this.data = this.dataUrl;
    console.log(this.data)
  }

  ngOnInit() {

    this.data.forEach((i) => {
      this.arrImagenes.push(
        {
          imagen: i.url,
          esImagen: i.url
        }
      );
    });
    const imagenArreglo = this.data.replace(/[\\"\[\]]/g, '');
    
  }

  moverContador(incremento: boolean) {
    if (incremento) {
      this.contador++;
    } else {
      this.contador--;
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

}
