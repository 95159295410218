import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {RegistrateCorreoComponent} from '../../modals/registrate-correo/registrate-correo.component';

@Component({
  selector: 'app-why-we-choose',
  templateUrl: './why-we-choose.component.html',
  styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {

  subMenu = false;
  constructor(
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {
  }

  modalRegistro() {
    this.dialog.open(RegistrateCorreoComponent, {
      width: '400px',
      height: '500px'
    });
  }

  quieroAyuda() {
    if (!this.subMenu) {
      this.subMenu = true;
    } else {
      this.subMenu = false;
    }
  }

}
