import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {enviroment} from '../variables';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactanosService {

    url: any;

    constructor(private http: HttpClient) {
        this.url = enviroment.apiUrl;
    }

    save(contactanos): Observable<any> {
        return this.http.post<any>(this.url + 'contactanos', contactanos);
    }
}
