import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserstoreService } from '../services/nuevaLogica/clientes/userstore.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/layout/header/widgets/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(
    private loginService: UserstoreService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if (this.loginService.validToken()) {
    //   return true;
    // } else {
    //  this.dialog.open(LoginComponent, {
    //     width: '350px'
    //   });
    //   return true;

    //   const currentUrl = this.router.url;
    //   this.router.navigate([currentUrl]);


    // }
    if (this.loginService.validToken()) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}

