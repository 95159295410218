import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Producto } from "../interfaces/productos/Producto";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  //   Agrega un favorito a un cliente
  // [POST] https://backend.sneakerstore.com.mx/nueva-base/productos/add-favorite-product

  // { {
  //     "productoId": 10000
  // }

  // Obtiene los favoritos de un cliente
  // [GET] https://backend.sneakerstore.com.mx/nueva-base/productos/get-favorite-products

  // Elimina un favorito de un cliente
  // [POST] https://backend.sneakerstore.com.mx/nueva-base/productos/delete-favorite-products

  // {
  //     "idProductoFavorito": 1
  // }

  favoritesSaved = new BehaviorSubject<Producto[]>([]);
  $favoritesSaved = this.favoritesSaved.asObservable();

  url = `${environment.apiUrlBack}/productos`;

  constructor(private http: HttpClient) {}

  getFavorites(): Observable<any> {
    return this.http
      .get(`${this.url}/get-favorite-products`)
      .pipe(tap((response: any) => this.favoritesSaved.next(response)));
  }

  addFavorite(productoId: number): Observable<any> {
    return this.http.post(`${this.url}/add-favorite-product`, {
      productoId,
    });
  }

  deleteFavorite(idProductoFavorito: number): Observable<any> {
    return this.http.post(`${this.url}/delete-favorite-products`, {
      idProductoFavorito,
    });
  }

  // test de favoritos
  getFavorite(): Observable<any> {
    return this.http.get(this.url + 'get-favorite-products');
  }

  cargaFavoritos(favoritos: any[]) {
    this.favoritesSaved.next(favoritos);
  }
}
