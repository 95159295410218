import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-home-slider-promotions",
  templateUrl: "./home-slider-promotions.component.html",
  styleUrls: ["./home-slider-promotions.component.scss"],
})
export class HomeSliderPromotionsComponent implements OnInit, AfterViewInit {
  @Input() screen: "principal" | "home" | "detail" = "home";
  @Input() isHideMobile: boolean;
  @ViewChild("slider", { static: true }) slider: ElementRef<HTMLDivElement>;

  typeScreen = {
    home: [
      {
        title: "Tu compra, tus reglas",
        subtitle:
          "Transferencia, depósito o pago con tarjeta...Tú decides, compra ahora y paga fácil",
      },
      {
        title: "El envío va por nuestra cuenta",
        subtitle:
          "A partir de compras de $4,000 pesos o más, el envío es gratis...",
      },
      {
        title: "¡Más pares, más descuentos!",
        subtitle:
          "Lleva más de un par a tu colección y observa como bajan los precios",
      },
    ],
    detail: [
      {
        title: "Envío gratis",
        subtitle:
          "Envío gratis a toda la república mexicana en compras superiores a $4,000.",
      },
      {
        title: "Servicio 24x7",
        subtitle: "Servicio online las 24 horas.",
      },
      {
        title: "Pago en línea",
        subtitle: "Pago en línea 100% seguro.",
      },
      {
        title: "Métodos de pago",
        subtitle:
          "Paga con tarjeta de crédito, débito. Meses sin intereses, depósito en efectivo y transferencia bancaria. Pregunta con tu asesor por la modalidad 50/50 y el sistema de apartado",
      },
    ],
  };

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.resizeScreen();
  }

  resizeScreen(): void {
    this.breakpointObserver
      .observe(["(max-width: 1300px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches && this.isHideMobile) {
          this.hideSlider();
        } else {
          this.showSlider();
        }
      });
  }

  hideSlider(): void {
    this.slider.nativeElement.style.display = "none";
  }

  showSlider(): void {
    this.slider.nativeElement.style.display = "block";
  }
}
