import { Component, OnInit } from '@angular/core';
import { ImageUploadService } from 'src/app/core/services/image-upload.service';
import { ProductosService } from 'src/app/core/services/nuevaLogica/productos/productos.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-image-search',
  templateUrl: './image-search.component.html',
  styleUrls: ['./image-search.component.scss']
})
export class ImageSearchComponent implements OnInit {
  previewUrl: string | ArrayBuffer | null = null;
  productos: any[] = [];
  data: any;
  postName: any;
  currentPage = 1;

  constructor(
    private imageUploadService: ImageUploadService,
    private router: Router,
    private productosService: ProductosService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getProductos(); // Llama inicialmente a getProductos para cargar los productos

    // Suscribirse a los cambios en la URL de la imagen
    this.imageUploadService.currentImageUrl.subscribe(url => {
      this.previewUrl = url;

      // Vaciar los productos cuando no hay imagen
      if (!url) {
        this.productos = [];
      }

      // Si hay una nueva imagen, realiza la búsqueda de productos
      if (url) {
        this.getProductos(); // Llama a getProductos de nuevo cuando haya una nueva imagen
      }
    });
  }

  getProductos() {
    // Vaciar los productos antes de realizar una nueva búsqueda
    this.productos = [];

    // Prepara los datos para la consulta de productos
    this.data = '{"productos": "sneakers", "marca": "' + '"}';
    
    this.productosService.get(JSON.parse(this.data)).subscribe(data => {
      this.productos = data;
    });
  }
}
