import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Producto } from "../../../core/interfaces/productos/Producto";
import { CarritoService } from "../../../core/services/nuevaLogica/checkout/carrito.service";
import { Router } from "@angular/router";
import { AddProductOfferComponent } from "src/app/shop/productos/modal/add-product-offer/add-product-offer.component";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "src/app/layout/header/widgets/login/login.component";
import { FavoritesService } from "src/app/core/services/favorites.service";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { SuccessSSToast } from "src/app/core/toast/success-ss-toast.component";
import { switchMap } from "rxjs/operators";
import { UserstoreService } from "src/app/core/services/nuevaLogica/clientes/userstore.service";
import { CompareService } from 'src/app/core/services/compare-service.service';

@Component({
  selector: "app-tarjeta-productos",
  templateUrl: "./tarjeta-productos.component.html",
  styleUrls: ["./tarjeta-productos.component.scss"],
})
export class TarjetaProductosComponent implements OnInit {
  @Input() producto: Producto;
  @Input() descuento?: boolean;
  @Input() fromSubasta?: boolean;
  @Input() theme: string;
  @Input() fromCambio?: boolean;
  @Output() fromCambioData = new EventEmitter<any>();
  options: GlobalConfig;
  isFavorite: boolean;
  favoriteCurrent: any;
  logged = false;

  constructor(
    private carritoService: CarritoService,
    private router: Router,
    private dialog: MatDialog,
    private favoritesService: FavoritesService,
    private toastrService: ToastrService,
    private loginService: UserstoreService,
    private compareService: CompareService,
  ) {}

  ngOnInit() {
    this.logged = this.loginService.validToken();
    this.getFavoritesSaved();
    console.log('Producto recibido en ngOnInit:', this.producto);
  }

  clickButton() {
    if (this.descuento == true) {
      this.addCartLogic();
    } else if (this.fromCambio == true) {
      this.fromCambioData.emit(this.producto);
    } else if (this.fromSubasta == true) {
      this.openModalAddOffer();
    } else {
      this.goToLink();
    }
  }

  goToLink() {
    sessionStorage.setItem("producto_descuento_talla", this.producto.talla);
    window.location.href = "/productos/detalle/" + this.producto.descripcionURL;

    // Logica de descuentos
    if (this.producto['activo']) {
      localStorage.setItem("descuento_talla", JSON.stringify({
        talla: this.producto.talla ? this.producto.talla : "false",
        nombre: this.producto.nombre ? this.producto.nombre : "",
        id: this.producto.idTallaProducto ? this.producto.idTallaProducto : 0
      }));
    }
  }

  addCartLogic() {
    this.carritoService.updateCarrito({
      precio: this.getPrecioVenta(this.producto),
      nombre: this.producto.nombre,
      idTallaSeleccionada: this.producto.idTallaProducto,
      talla: this.producto.talla,
      imagen: this.producto.imagen,
      cantidad: 1,
      idDescuento: this.producto.idDescuento,
      idTipoDescuento: this.producto.idTipoDescuento,
      descuento: this.producto.descuento,
    });

    sessionStorage.removeItem("orderType");
    this.router.navigate(["/checkout/pago"]);
  }

  getPrecioVenta(producto): number {
    if (producto.idTipoDescuento != null) {
      if (producto.idTipoDescuento === 1) {
        return (
          producto.precioVenta -
          (producto.precioVenta * producto.descuento) / 100
        );
      }

      return producto.precioVenta - producto.descuento;
    }

    return producto.precioVenta;
  }

  openModalAddOffer() {
    if (
      sessionStorage.getItem("token") != null ||
      sessionStorage.getItem("token") != undefined
    ) {
      this.dialog
        .open(AddProductOfferComponent, {
          data: {
            subastaProductoId: this.producto.subastaProductoId,
          },
          width: "550px",
        })
        .afterClosed()
        .subscribe((reload) => {
          if (reload) {
          }
        });
    } else {
      this.dialog.open(LoginComponent, {
        width: "350px",
      });
    }
  }

  Compare(event): void {
    event.stopPropagation();
    this.compareService.addProducto({
      id: this.producto.idProducto.toString(),
      nombre: this.producto.nombre,
      imagen: this.producto.imagen,
      idTallaProducto: this.producto.idTallaProducto,
      talla: this.producto.talla,
      idDescuento: this.producto.idDescuento,
      idTipoDescuento: this.producto.idTipoDescuento,
      descuento: this.producto.descuento,
      precioVenta: this.producto.precioVenta,
      descripcionURL: this.producto.descripcionURL
    });
  }

  addFavorite(event): void {
    event.stopPropagation();

    if (!this.isFavorite) {
      this.favoritesService
        .addFavorite(this.producto.idProducto)
        .subscribe(() => {
          this.openToast("¡Producto agregado a favoritos con éxito!");
          this.isFavorite = true;
          this.loadFavorites();
        });
    } else {
      this.favoritesService
        .deleteFavorite(this.favoriteCurrent.idProductoFavorito)
        .pipe(
          switchMap(() => {
            this.isFavorite = false;
            this.openToast("¡Producto eliminado de favoritos con éxito!");
            return this.favoritesService.getFavorites();
          })
        )
        .subscribe();
    }
  }

  private getFavoritesSaved(): void {
    this.favoritesService.$favoritesSaved.subscribe({
      next: (list) => {
        if (list.length > 0) {
          const index = list.find(
            (favorite) => favorite.idProducto === this.producto.idProducto
          );

          this.isFavorite = !!index;
        }
      },
    });
  }

  private openToast(title: string): void {
    this.toastrService.toastrConfig.toastComponent = SuccessSSToast;
    this.toastrService.toastrConfig.toastClass = "sstoast";
    const inserted = this.toastrService.show(title, "", this.options);
  }

  // test de favoritos
  loadFavorites() {
    this.favoritesService.getFavorites()
    .subscribe(data => {
      this.favoritesService.cargaFavoritos(data);
      this.favoritesService.$favoritesSaved.subscribe(datos => {
        this.favoriteCurrent = datos.find(i => i.idProducto == this.producto.idProducto);
      })
    });
  }
}
