import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material';
import {Notificaciones} from '../../../../notificaciones';
import {UserstoreService} from '../../../../core/services/nuevaLogica/clientes/userstore.service';
import {ProspectService} from '../../../../core/services/nuevaLogica/prospects/prospect.service';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit  {
  genero = [
    {
      value: 'M',
      descripcion: 'Masculino'
    },
    {
      value: 'F',
      descripcion: 'Femenino'
    }
  ];
  userForm = new FormGroup({
    nombre: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^([A-Za-z ÑñáéíóúÁÉÍÓÚ]+)$')])),
    apellidos: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^([A-Za-z ÑñáéíóúÁÉÍÓÚ]+)$')
    ])),
    telefono: new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[0-9]+$')
    ])),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    repeatPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    fechaNacimiento: new FormControl('', Validators.required),
    genero: new FormControl('', Validators.required)
  });

  constructor(protected userStoreService: UserstoreService,
              public dialogRef: MatDialogRef<RegisterUserComponent>,
              private notificaciones: Notificaciones,
              private prospectService: ProspectService) {
  }
  
  ngOnInit() {
      this.setProspect();
  }

  setProspect() {
    const prospect_id = localStorage.getItem("prospect_id");

    if(prospect_id != undefined && prospect_id != "") {
        const prospectId = parseInt(prospect_id);

        this.prospectService.getProspect(prospectId).subscribe((data: any) => {
            this.userForm.controls.nombre.setValue(data.name);
            this.userForm.controls.email.setValue(data.email);
            this.userForm.controls.telefono.setValue(data.phone);
        });
    }
}

  registerUser() {
    // return console.log(this.userForm)
    this.notificaciones.carga('Ingresando datos');
    this.userStoreService.post({
      user: window.btoa(JSON.stringify(this.userForm.value))
    }).subscribe(userData => {
        this.notificaciones.exito('Registro completado');
        sessionStorage.setItem('token', userData.token);
        window.location.reload();
      }, error => {
        if (error.status === 422) {
          this.notificaciones.error('Este correo ya se encuentra registrado');
          this.userForm.controls.email.setValue('');
          this.userForm.controls.password.setValue('');
          this.userForm.controls.repeatPassword.setValue('');
        } else {
          this.notificaciones.error('Ha ocurrido un error, vuelve a intentarlo más tarde');
        }
      }
    );
  }

  comprobarPass(): Promise<any> {
    // debugger;
    return new Promise((resolve) => {
      if (this.userForm && (this.userForm.controls.repeatPassword.value === this.userForm.controls.password.value)) {
        resolve(null);
      } else {
        resolve({noMatch: true});
      }
    });
  }
}

