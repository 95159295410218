import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderMenu } from "src/app/core/interfaces/header/headerInterface";
import { LoginComponent } from "src/app/layout/header/widgets/login/login.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-menu-responsive",
  templateUrl: "./menu-responsive.component.html",
  styleUrls: ["./menu-responsive.component.scss"],
  // animate opacity 0 to 1
  animations: [
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    // transition('state1 <=> state2', animate('100ms ease-in'))
    trigger("fadeInOut", [
      transition("void => *", [
        style({ height: 0 }),
        animate("200ms ease-in-out", style({ height: "*" })),
      ]),
      transition("* => void", [
        style({ height: "*" }),
        animate("200ms ease-in-out", style({ height: "0" })),
      ]),
    ]),
  ],
})
export class MenuResponsiveComponent implements OnInit {
  @Input() menu: HeaderMenu[];
  @Input() isLogged: boolean;
  @Output() closeMenu = new EventEmitter<void>();
  @Output() openLogin = new EventEmitter<void>();

  constructor(private router: Router, private dialog: MatDialog) {}

  rowSelected: number = null;

  ngOnInit() {
    console.log(this.menu);
  }

  selectRow(itemMenu: HeaderMenu, index: number): void {
    if (itemMenu.drop) {
      if (this.rowSelected === index) {
        this.rowSelected = null;
      } else {
        this.rowSelected = index;
      }
    } else {
      this.router.navigate([itemMenu.url]);
      this.close();
    }
  }

  close() {
    this.closeMenu.emit();
  }
  register() {
    this.dialog.open(LoginComponent, {
      minWidth: '400px'
    })
  }

  openStore() {
    if (navigator.userAgent.indexOf("Android") != -1) {
      window.open("https://play.google.com/store/apps/details?id=com.DesarrollaTech.SneakersStore&pli=1");
    }

    if (navigator.userAgent.indexOf("like Mac") != -1) {
      window.open("https://apps.apple.com/us/app/sneaker-store/id6447260033");
    }
  }
}
