import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DistribuidoresService } from 'src/app/core/services/nuevaLogica/distribuidores/distribuidores.service';
import { Notificaciones } from 'src/app/notificaciones';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  @ViewChild('registration', { static: false}) formulario: ElementRef;
  earnings: string = '$5,000';
  registrationForm: FormGroup;

  projectedSales: number = 0;
  totalCommission: number = 0;
  averageTicket: number = 0.05;

  ticketPromedio = [
    { id: 1, value: 0.05, money: 6000 },
    { id: 2, value: 0.07, money: 8000 },
    { id: 3, value: 0.1, money: 15000 },
  ];

  constructor(
    private fb: FormBuilder,
    private distribuidoresService: DistribuidoresService,
    private notificaciones: Notificaciones,
    private router: Router
  ) {
    this.registrationForm = this.fb.group({
      nombre: [''],
      apellido: [''],
      email: [''],
      telefono: ['']
    });
  }

  ngOnInit(): void {
    this.handleCalculator(this.projectedSales);
    this.updateSliderBackground(this.projectedSales);
  }

  updateEarnings(event: Event): void {
    const pairs = parseInt((event.target as HTMLInputElement).value);
    this.projectedSales = pairs;
    this.handleCalculator(pairs);
    this.updateSliderBackground(pairs);
  }

  handleCalculator(peers: number = 0): void {
    if (peers === 0) {
      this.totalCommission = 0;
      return;
    }
    const projectedSalesCalc = peers * this.averageTicket;
    const cashFlow = this.ticketPromedio.find(op => op.value === this.averageTicket);
    const money = cashFlow ? cashFlow.money : 0;
    this.totalCommission = projectedSalesCalc * money;
  }

  convertToMoney(amount: number | string): string {
    const money = Number(amount);
    return money.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  }

  navigateToLanzamientos() {
    this.router.navigate(['/productos/lanzamientos']);
  }

  onSubmit(): void {
    this.notificaciones.carga("Enviando");
    if (this.registrationForm.valid) {
      this.distribuidoresService.postProspectoDistribuidores(this.registrationForm.value)
        .subscribe(
          data => {
            console.log(data);
            this.notificaciones.exito("Información enviada");
            this.registrationForm.reset();
          },
          error => {
            console.error(error);
            this.notificaciones.error("Error al enviar el formulario");
          }
        );
    } else {
      console.log('Formulario no válido');
      this.notificaciones.error("Formulario inválido");
    }
  }

  onTicketChange(event: Event): void {
    const value = parseFloat((event.target as HTMLSelectElement).value);
    this.averageTicket = value;
    this.handleCalculator(this.projectedSales);
  }

  updateSliderBackground(value: number): void {
    const slider = document.querySelector('input[type="range"]') as HTMLInputElement;
    const percent = (value / parseInt(slider.max)) * 100;
    slider.style.setProperty('--value', `${percent}%`);
  }

  // scrollForm() {
  //   const target = this.formulario.nativeElement;
  //   window.scrollTo({
  //     top: target.offsetTop,
  //     behavior: 'smooth'
  //   });
  // }
  scrollForm() {
    const target = this.formulario.nativeElement;
    this.smoothScrollTo(target.offsetTop, 600);
  }

  smoothScrollTo(endY: number, duration: number) {
    const startY = window.pageYOffset;
    const distanceY = (endY - startY) - 100;
    let startTime: number | null = null;

    const step = (currentTime: number) => {
      if (!startTime) {
        startTime = currentTime;
      }
      const timeElapsed = currentTime - startTime;
      const nextY = this.easeInOutQuad(timeElapsed, startY, distanceY, duration);
      window.scrollTo(0, nextY);
      if (timeElapsed < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  easeInOutQuad(t: number, b: number, c: number, d: number) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

}
