import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ShopComponent } from "./shop.component";
import { LoginMobileComponent } from "../layout/header/login-mobile/login-mobile.component";
import { LandingJordanComponent } from "./landing-jordan/landing-jordan.component";
import { MarcasComponent } from "../layout/footer/widgets/marcas/marcas.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { CompareProductComponent } from './compare-product/compare-product.component';
import { ImageSearchComponent } from './image-search/image-search.component';

const routes: Routes = [
  {
    path: "",
    component: ShopComponent,
    children: [
      {
        path: "login",
        component: LoginMobileComponent,
      },
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      // {
      //   path: 'rifa',
      //   loadChildren: () => import('./rifa/rifa.module').then(m => m.RifaModule)
      // },
      {
        path: "distribuidores",
        loadChildren: () =>
          import("./distribuidores/distribuidores.module").then(
            (m) => m.DistribuidoresModule
          ),
      },
      // {
      //   path: 'outlet',
      //   loadChildren: () => import('./outlet/outlet.module').then(m => m.OutletModule)
      // },
      {
        path: "nuestra-empresa",
        loadChildren: () =>
          import("./nuestra-empresa/nuestra-empresa.module").then(
            (m) => m.NuestraEmpresaModule
          ),
      },
      {
        path: "atencion-clientes",
        loadChildren: () =>
          import("./atencion-clientes/atencion-clientes.module").then(
            (m) => m.AtencionClientesModule
          ),
      },
      {
        path: "productos",
        loadChildren: () =>
          import("./productos/productos.module").then((m) => m.ProductosModule),
      },
      {
        path: "marcas/:name",
        loadChildren: () =>
          import("./yeezy/yeezy.module").then((m) => m.YeezyModule),
      },
      // {
      //   path: 'agendar-cita',
      //   loadChildren: () => import('./agendar-cita/agendar-cita.module').then(m => m.AgendarCitaModule)
      // },
      {
        path: "checkout",
        loadChildren: () =>
          import("./checkout/checkout.module").then((m) => m.CheckoutModule),
      },
      {
        path: "usuario",
        loadChildren: () =>
          import("./usuario/usuario.module").then((m) => m.UsuarioModule),
      },
      {
        path: "prime",
        loadChildren: () =>
          import("./prime/prime.module").then((m) => m.PrimeModule),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: "sneakers-retail",
        loadChildren: () =>
          import("./retail/retail.module").then((m) => m.RetailModule),
      },
      {
        path: "sneakers-retail",
        loadChildren: () =>
          import("./retail/retail.module").then((m) => m.RetailModule),
      },
      // {
      //   path: "productos/lanzamientos",
      //   component: LandingJordanComponent,
      // },

      {
        path: "marcas",
        component: MarcasComponent,
      },
      {
        path: "sales-partners",
        component : LandingPageComponent
      },
      {
        path: "compare-product",
        component : CompareProductComponent
      },
      {
        path: "image-search",
        component : ImageSearchComponent
      },
      {
        path: "**",
        redirectTo: "",
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {}
