import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import * as jwt_decode from "jwt-decode";
import { User } from "../../../interfaces/clientes/User";

@Injectable({
  providedIn: "root",
})
export class UserstoreService {
  url: string;
  token: any;
  urlFinanzas: any;
  urlCupones: any;
  urlOrder: any;
  urlOferta: any;

  $user = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlClientes + "usuarios/ecommerce";
    this.urlFinanzas = environment.apiUrlFinanzas;
    this.urlCupones = environment.apiUrlCupones;
    this.urlOrder = environment.apiUrlOrdenes;
    this.urlOferta = environment.apiUrlEcommerce;
    this.token = sessionStorage.getItem("token");
  }

  post(user): Observable<any> {
    return this.http.post(this.url, user);
  }

  getUser(): Observable<{ nombre: string }> {
    return this.http.get<{ nombre: string }>(this.url);
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(this.url, user);
  }

  getInfoUser(): Observable<User> {
    return this.http.get<User>(this.url + "/info");
  }

  getCodigo(): Observable<any> {
    return this.http.get<string>(this.url + "/codigo");
  }

  getDirecciones(): Observable<any> {
    return this.http.get<string>(this.url + "/direcciones");
  }

  getSaldoFavor(): Observable<any> {
    return this.http.get<any>(`${this.urlFinanzas}saldo-favor/test_sneakerstore@hotmail.com`);
  }

  getCupones(): Observable<any> {
    return this.http.get<any>(`${this.urlCupones}get-cupon-usuario/`);
  }

  getSubasta(): Observable<any> {
    return this.http.get<any>(`${this.urlOrder}get-user-subasta-offers`);
  }

  getOferta(): Observable<any> {
    const email = sessionStorage.getItem("userEmail");
    return this.http.post<any>(`${this.urlOferta}ofertas`, { email });
  }

  updateCostoOferta(id: number, newCost: number): Observable<any> {
    const updateUrl = `${this.urlOferta}ofertas/actualizar-costo`;
    return this.http.post(updateUrl, { id, newCost });
  }

  updateOfferStatus(id: number, status: number): Observable<any> {
    const updateUrl = `${this.urlOferta}ofertas/actualizar-status`;
    return this.http.post(updateUrl, { id, status });
  }

  createOffer(id: number, idTallaSeleccionada: number, talla: string, email: string, newCost: number): Observable<any> {
    const createUrl = `${this.urlOferta}ofertas/crear`;
    return this.http.post(createUrl, { id, idTallaSeleccionada, talla, email, newCost });
  }

  login(credenciales: string): Observable<any> {
    return this.http.post(this.url + "/login", { credenciales });
  }

  validToken() {
    if (this.token) {
      const now = Date.now().valueOf() / 1000;
      const jwt = jwt_decode(this.token);

      if (now < jwt.exp) {
        return true;
      } else {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("infoCliente");
        sessionStorage.removeItem("metodoPago");
        return false;
      }
    } else {
      return false;
    }
  }

  getEmailUser(): string {
    return sessionStorage.getItem('userEmail').toString();
  }
}
