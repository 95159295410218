import {Component, Input, OnInit, Optional, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProspectService} from '../../../../core/services/nuevaLogica/prospects/prospect.service';
import {Notificaciones} from '../../../../notificaciones';

@Component({
    selector: 'app-modal-prospect',
    templateUrl: './modal-prospect.component.html',
    styleUrls: ['./modal-prospect.component.scss']
  })
export class ModalProspectComponent implements OnInit {

    userForm = new FormGroup({
        'name': new FormControl('', Validators.compose([Validators.required])),
        'email': new FormControl('', Validators.compose([Validators.required, Validators.email])),
        'phone': new FormControl('', Validators.compose([Validators.required, Validators.minLength(10)])),
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                @Optional() private dialogRef: MatDialogRef<ModalProspectComponent>,
                private prospectService: ProspectService,
                private notificacionesService: Notificaciones) {
    
    }

    ngOnInit() {

    }

    register() {
      this.notificacionesService.carga('Registrando Prospecto');
      this.prospectService.addProspect({
          name: this.userForm.controls.name.value,
          email: this.userForm.controls.email.value,
          phone: this.userForm.controls.phone.value,
          products: [
              this.data.productName
          ]
      }).subscribe(
        (data_response:any) => {
          this.notificacionesService.cerrar();          
          localStorage.setItem("prospect_id", data_response.prospect_id);
          this.dialogRef.close(true);
        },
        (error) => {
          this.notificacionesService.error('Error al agregar prospecto');
        }
      );
    }

    close() {
      this.dialogRef.close(false);
    }
}