import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Carrito} from '../../../interfaces/checkout/carrito';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  elementosCarrito = new Subject<Carrito[]>();

  constructor() {
    this.elementosCarrito.next(this.getCarrito());
  }

  updateCarrito(infoCarrito) {
    const carritoActual = sessionStorage.getItem('carrito') ? this.desencriptarCarrito() : [];

    const productoPonTuPrecio = carritoActual.some(item => item.origen === 'ponTuPrecio');
  
    if (infoCarrito.origen === 'ponTuPrecio') {
      if (productoPonTuPrecio) {
        console.log("Ya hay un producto de PonTuPrecio en el carrito, no se puede agregar otro.");
        return;
      }
      carritoActual.push(infoCarrito);
    } else {
      if (carritoActual.length > 0) {
        for (let i = 0; i < carritoActual.length; i++) {
          if ((i === carritoActual.length - 1) && carritoActual[i].idTallaSeleccionada !== infoCarrito.idTallaSeleccionada) {
            carritoActual.push(infoCarrito);
            break;
          }
          if (carritoActual[i].idTallaSeleccionada === infoCarrito.idTallaSeleccionada) {
            carritoActual[i].cantidad += infoCarrito.cantidad;
            carritoActual[i].montoApartado += infoCarrito.montoApartado;
          }
        }
      } else {
        carritoActual.push(infoCarrito);
      }
    }
  
    this.guardarCarrito(carritoActual);
    this.elementosCarrito.next(carritoActual);
  }
  
  

  getCarrito(): Carrito[] {
    return sessionStorage.getItem('carrito') ? this.desencriptarCarrito() : null;
  }

  eliminarElementoCarrito(index: number): Carrito[] {
    const carrito = this.desencriptarCarrito();
    carrito.splice(index, 1);
    this.elementosCarrito.next(carrito);
    this.guardarCarrito(carrito);
    return carrito;
  }

  modificarCantidadProducto (agregarProducto: boolean, index: number) {
    const carrito = this.desencriptarCarrito();
    if (agregarProducto) {
      carrito[index].cantidad++;
    } else {
      carrito[index].cantidad--;
    }
    this.guardarCarrito(carrito);
    return carrito;
  }

  modificarMontoApartado(index: number, nuevoMonto: number) {
    const carrito = this.desencriptarCarrito();

    carrito[index].montoApartado = nuevoMonto;
 
    this.guardarCarrito(carrito);
    return carrito;
  }

  modificarApartado(state, nuevoMonto, index) {
    const carrito = this.desencriptarCarrito();

    if (state == false) {
      carrito[index].apartadoValido = state;
    } else {
      carrito[index].apartadoValido = state;
      carrito[index].montoApartado = nuevoMonto;
    }
 
    this.guardarCarrito(carrito);
    return carrito;
  }

  modificarCantidadProductoPorInput (agregarProducto: boolean, index: number, cantidad: number) {
    const carrito = this.desencriptarCarrito();
    if (agregarProducto) {
      carrito[index].cantidad = cantidad;
    } else {
      carrito[index].cantidad = cantidad;
    }
    this.guardarCarrito(carrito);
    return carrito;
  }

  private desencriptarCarrito() {
    return JSON.parse(window.atob(sessionStorage.getItem('carrito')));
  }

  private guardarCarrito(carritoActual) {
    sessionStorage.setItem('carrito', window.btoa(JSON.stringify(carritoActual)));
  }

  prepararCustumeKart(carrito: any[]) {
    const carritoActual = sessionStorage.getItem('carrito') ? this.desencriptarCarrito() : [];

    if (carritoActual.length == 0) {
      this.guardarCarrito(carrito);
    }
  }

  
}
