import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductosService } from 'src/app/core/services/nuevaLogica/productos/productos.service';

@Component({
  selector: 'app-seleccionar-talla',
  templateUrl: './seleccionar-talla.component.html',
  styleUrls: ['./seleccionar-talla.component.scss']
})
export class SeleccionarTallaComponent implements OnInit {
  producto: any;
  contador = 0;
  tallaSeleccionada: number;

  constructor(
    public dialogRef: MatDialogRef<SeleccionarTallaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private productoService: ProductosService
  ) { }

  ngOnInit() {
    this.getProductoByDescripcion();
  }

  getProductoByDescripcion() {
    this.productoService.getByDescripcion(this.data).subscribe(data => this.producto = data);
  }

  guardarSeleccion() {
    this.dialogRef.close({
      descripcionURL: this.data,
      precio: this.getPrecioVenta(this.producto),
      nombre: this.producto.nombre,
      idTallaSeleccionada: this.tallaSeleccionada,
      talla: this.producto.tallas.find(dato => dato.id === this.tallaSeleccionada).talla,
      imagen: this.producto.imagenes[0].url,
      cantidad: 1,
      idDescuento: this.producto.idDescuento
    });
  }

  getPrecioVenta(producto): number {
    if (!producto.idDescuento) {
      return producto.precioVenta;
    } else {
      if (producto.idTipoDescuento === 1) {
        return producto.precioVenta - ((producto.precioVenta * producto.descuento) / 100);
      }
      return producto.precioVenta - producto.descuento;
    }
  }

}
