import { Component, Input, OnInit, Optional } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { RegisterUserComponent } from "../register-user/register-user.component";
import { Notificaciones } from "../../../../notificaciones";
import { UserstoreService } from "../../../../core/services/nuevaLogica/clientes/userstore.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  @Input() esModal = true;
  userForm = new FormGroup({
    email: new FormControl(
      "",
      Validators.compose([Validators.required, Validators.email])
    ),
    password: new FormControl(
      "",
      Validators.compose([Validators.required, Validators.minLength(8)])
    ),
  });
  inputType = "password";

  tabSelected: "login" | "register" = "login";

  constructor(
    @Optional() private dialogRef: MatDialogRef<LoginComponent>,
    private userService: UserstoreService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private router: Router,
    private notificacionesService: Notificaciones
  ) {}

  login() {
    this.notificacionesService.carga("Iniciando sesión");
    this.userService.login(btoa(JSON.stringify(this.userForm.value))).subscribe(
      (userData) => {
        sessionStorage.removeItem("direccionInvitado");
        sessionStorage.setItem("token", userData.token);
        sessionStorage.setItem("userEmail", this.userForm.controls.email.value);
        this.toastrService.success("Acceso correcto");
        if (this.esModal) {
          this.dialogRef.close();
        }
        this.notificacionesService.cerrar();
        window.location.reload();
      },
      (error) => {
        this.notificacionesService.cerrar();
        if (error.status === 401) {
          this.toastrService.error("Credenciales incorrectas");
          this.userForm.reset();
          this.userForm.controls.idEmpresa.setValue(1);
        } else {
          this.notificacionesService.error(
            "Hubo un error al iniciar sesión, vuelve a intentarlo más tarde"
          );
        }
      }
    );
  }

  restaurarPassword() {
    if (this.esModal) {
      this.dialogRef.close();
    }
    this.router.navigate(["/usuario/restaurar-password"]);
  }

  openRegistro() {
    if (this.esModal) {
      this.dialogRef.close();
    }
    this.dialog.open(RegisterUserComponent, {
      width: "350px",
    });
  }
}
