import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
  MatToolbarModule,
} from "@angular/material";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LandingFixService } from "../core/services/landing-fix.service";
import { NewsletterService } from "../core/services/nuevaLogica/clientes/newsletter.service";
import { FooterComponent } from "./footer/footer.component";
import { CategoriesComponent } from "./footer/widgets/categories/categories.component";
import { CopyrightComponent } from "./footer/widgets/copyright/copyright.component";
import { SocialComponent } from "./footer/widgets/social/social.component";
import { WhyWeChooseComponent } from "./footer/widgets/why-we-choose/why-we-choose.component";
import { HeaderComponent } from "./header/header.component";
import { LoginComponent } from "./header/widgets/login/login.component";
import { RegisterUserComponent } from "./header/widgets/register-user/register-user.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { LoginMobileComponent } from "./header/login-mobile/login-mobile.component";
import { ModalProspectComponent } from "./header/widgets/modal-prospect/modal-prospect.component";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MarcasComponent } from "./footer/widgets/marcas/marcas.component";
import { MatMenuModule } from "@angular/material/menu";
import { NgAisModule } from "angular-instantsearch";
import { RegistrateCorreoComponent } from "./footer/modals/registrate-correo/registrate-correo.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { GeneralesModule } from "../shared/generales/generales.module";
import { HeaderShortComponent } from './header-short/header-short.component';
import { DownloadAppComponent2 } from "../shared/generales/download-app/download-app.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CategoriesComponent,
    WhyWeChooseComponent,
    CopyrightComponent,
    SocialComponent,
    RegisterUserComponent,
    LoginComponent,
    ModalProspectComponent,
    LoginMobileComponent,
    MarcasComponent,
    RegistrateCorreoComponent,
    BreadcrumbComponent,
    HeaderShortComponent,
    DownloadAppComponent2
  ],
  exports: [
    HeaderComponent,
    TranslateModule,
    FooterComponent,
    LoginMobileComponent,
    MarcasComponent,
    BreadcrumbComponent,
    LoginComponent,
    ModalProspectComponent,
    HeaderShortComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatRadioModule,
    MatSelectModule,
    MatSelectModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatBadgeModule,
    MatButtonModule,
    MatMenuModule,
    NgAisModule.forRoot(),
    MatDatepickerModule,
    MatCheckboxModule,
    MatCardModule,
    GeneralesModule,
    MatTabsModule,
  ],
  providers: [LandingFixService, NewsletterService],
  entryComponents: [
    RegisterUserComponent,
    LoginComponent,
    RegistrateCorreoComponent,
    ModalProspectComponent,
  ],
})
export class LayoutModule {}
