import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { AngoliaSearchRequest } from 'src/app/core/interfaces/atencion-clientes/angolia/AngoliaSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class AngoliaService {

    constructor(private http: HttpClient) {
    
    }

    GetAngoliaResults(body: AngoliaSearchRequest): Observable<any> {
        const url = "https://backend.sneakerstore.com.mx/angolia-search";
        return this.http.post(url, body);
    }
}